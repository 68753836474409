import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Typography, makeStyles, Grid } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  itemDiv: {
    textAlign: 'center',
    border: '1px solid lightgray',
    borderRadius: 5
  }
}));

const ResultatInfos = ({ resultat, className, ...rest }) => {
  const classes = useStyles();

  if (!resultat) {
    return null;
  }
  return (
    <Grid container spacing={3}>
      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Inscrits
          </Typography>
          <Typography variant="h3">{resultat.inscrits}</Typography>
        </div>
      </Grid>
      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Votants
          </Typography>
          <Typography variant="h3">{resultat.votant}</Typography>
        </div>
      </Grid>
      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Suffrages exprimés
          </Typography>
          <Typography variant="h3">{resultat.suffrageExprime}</Typography>
        </div>
      </Grid>

      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Taux de participation
          </Typography>
          <Typography variant="h3">
            {`${resultat.tauxParticipation} %`}
          </Typography>
          <Typography
            component="p"
            style={{ fontSize: 10, fontStyle: 'italic' }}
          >
            Calculé...
          </Typography>
        </div>
      </Grid>

      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Bulletins nuls
          </Typography>
          <Typography variant="h3">{resultat.tauxBulletinNul} %</Typography>
          <Typography component="p">{resultat.bulletinNul}</Typography>
        </div>
      </Grid>
      <Grid item xs={6} sm={4}>
        <div className={classes.itemDiv}>
          <Typography gutterBottom style={{ fontSize: 14 }}>
            Bulletins blancs
          </Typography>

          <Typography variant="h3">{resultat.tauxBulletinBlanc} %</Typography>
          <Typography component="p">{resultat.bulletinBlanc}</Typography>
        </div>
      </Grid>

      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

ResultatInfos.propTypes = {
  className: PropTypes.string
};

export default ResultatInfos;
