import 'react-perfect-scrollbar/dist/css/styles.css';
import React from 'react';
import { Provider, connect } from 'react-redux';
import { Route, Routes, Navigate, BrowserRouter } from 'react-router-dom';

import { ThemeProvider } from '@material-ui/core';
import GlobalStyles from 'src/components/GlobalStyles';
import theme from 'src/theme';
import store, { history } from './store';

import DashboardLayout from 'src/layouts/DashboardLayout';
import MainLayout from 'src/layouts/MainLayout';
import ElecteurListView from 'src/views/electeur/ElecteurListView/ElecteurListView.container';
import DashboardView from 'src/views/reports/DashboardView/DashboardView.component';
import LoginView from 'src/views/auth/LoginView.container';
import NotFoundView from 'src/views/errors/NotFoundView';
import { isTokenValid } from './views/auth/Auth.selectors';
import Toaster from './components/Toaster/Toaster.container';
import IdentifiantsView from './views/auth/Identifiants/IdentifiantsView.container';
import AddElecteur from './views/electeur/ElecteurListView/AddElecteur';
import LieuVoteView from './views/lieuxvote/LieuVoteView.component';
import RapportView from './views/reports/DashboardView/RapportView.component';
import ElecteurAvaliderView from './views/electeur/ElecteurAvaliderView.component';
import AssociationView from './views/association/AssociationView.component';
import SmsView from './views/reports/SmsView.component';
import VoteElecteurView from './views/vote/VoteElecteurView/VoteElecteurView.component';
import ResultatView from 'src/views/resultat/ResultatView/ResultatView.component';

const Content = props => {
  return (
    <Routes>
      <Route
        path="app"
        element={
          props.isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />
        }
      >
        <Route path="dashboard" element={<DashboardView />} />
        <Route path="electeurs" element={<ElecteurListView />} />
        <Route path="avalider" element={<ElecteurAvaliderView />} />
        <Route path="ajoutelecteur" element={<AddElecteur />} />
        <Route path="statsms" element={<SmsView />} />
        <Route path="vote" element={<VoteElecteurView />} />
        <Route path="identifiants" element={<IdentifiantsView />} />
        <Route path="lieuxvote" element={<LieuVoteView />} />
        <Route path="association" element={<AssociationView />} />
        <Route path="rapport" element={<RapportView />} />
        <Route path="resultats" element={<ResultatView />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
      <Route path="/" element={<MainLayout />}>
        <Route
          path="/"
          element={
            <Navigate to={props.isLoggedIn ? 'app/dashboard' : '/login'} />
          }
        />
        <Route path="404" element={<NotFoundView />} />
        <Route path="login" element={<LoginView />} />
        <Route path="logout" element={<Navigate to="/login" />} />
        <Route path="*" element={<Navigate to="/404" />} />
      </Route>
    </Routes>
  );
};

const RouteContainer = connect(state => {
  return {
    isLoggedIn: isTokenValid(state)
  };
})(Content);

const App = () => (
  <Provider store={store}>
    <BrowserRouter history={history}>
      <ThemeProvider theme={theme}>
        <Toaster />
        <GlobalStyles />
        <RouteContainer />
      </ThemeProvider>
    </BrowserRouter>
  </Provider>
);

export default App;
