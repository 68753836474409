import { SAISIR_VOTE, LIRE_STATS_VOTE } from './Vote.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function enregistrerVoteElecteur(numero, aVote) {
  return function(dispatch) {
    dispatch(
      fetchApiAction('electeur:vote', { url: { numero, aVote } }, result => ({
        type: SAISIR_VOTE,
        data: result
      }))
    );
  };
}

export function lireStatsVotes() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('electeur:lireStatVote', {}, result => ({
        type: LIRE_STATS_VOTE,
        data: result
      }))
    );
  };
}
