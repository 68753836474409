import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles,
  Divider,
  colors,
  LinearProgress,
  TextField,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
// import moment from 'moment';
import {
  getCec,
  getDepartementPolitique,
  getLieuxVote
} from 'src/views/auth/Auth.selectors';
import {
  listerCec,
  listerDepartementPolitique,
  listerLieuxVote
} from 'src/views/auth/Auth.actions';
import { requestResultsData } from '../Dashboard.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import ResultatInfos from './ResultatInfos';
import ResultatCandidat from './ResultatCandidat';
import { getResultatData } from '../Dashboard.selectors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  partisant: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.cyan[800]
  }
}));

const RapportView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const deps = useSelector(state => getDepartementPolitique(state));
  const cecs = useSelector(state => getCec(state));

  const resultat = useSelector(state => getResultatData(state));
  const fetching = useSelector(state =>
    getIsFetching(state, 'dashboard:resultat')
  );

  const [type, setType] = useState('Législative');
  const [annee, setAnnee] = useState('2021');
  const [cec, setCec] = useState('NULL');
  const [dp, setDp] = useState('NULL');
  const [lv, setLv] = useState('NULL');
  const [listeLv, setListeLv] = useState([]);

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (!cecs) {
      dispatch(listerCec());
    }
  }, [cecs, dispatch]);

  useEffect(() => {
    if (!deps) {
      dispatch(listerDepartementPolitique());
    }
  }, [deps, dispatch]);

  useEffect(() => {
    if (!resultat) {
      dispatch(requestResultsData(type, annee, cec, dp, lv));
    }
  }, [resultat, dispatch, type, annee, cec, dp, lv]);

  // const listeAnnee = () => {
  //   const res = [];
  //   const thisYear = moment().year();
  //   const borneMin = 20;

  //   for (let i = 0; i < borneMin; i += 1) {
  //     res.push(thisYear - i);
  //   }
  //   return res;
  // };

  const computeListeLvDp = val => {
    return lieuxVotes.filter(
      lieuVote =>
        lieuVote.departementPolitique &&
        lieuVote.departementPolitique.code === val
    );
  };

  const computeListeLvCec = val => {
    return lieuxVotes.filter(
      lieuVote => lieuVote.cec && lieuVote.cec.code === val
    );
  };

  const handleChangeType = event => {
    setType(event.target.value);
    setAnnee('');
    dispatch(requestResultsData(event.target.value, annee, cec, dp, lv));
  };

  const handleChangeAnnee = event => {
    setAnnee(event.target.value);
    dispatch(requestResultsData(type, event.target.value, cec, dp, lv));
  };

  const handleChangeCec = event => {
    setCec(event.target.value);
    setDp('NULL');
    setLv('NULL');
    setListeLv(computeListeLvCec(event.target.value));
    dispatch(
      requestResultsData(type, annee, event.target.value, 'NULL', 'NULL')
    );
  };

  const handleChangeDp = event => {
    setDp(event.target.value);
    setCec('NULL');
    setLv('NULL');
    setListeLv(computeListeLvDp(event.target.value));

    dispatch(
      requestResultsData(type, annee, 'NULL', event.target.value, 'NULL')
    );
  };

  const handleChangeLv = event => {
    setLv(event.target.value);
    dispatch(requestResultsData(type, annee, cec, dp, event.target.value));
  };

  return (
    <Page className={classes.root} title="Resultat">
      <Container maxWidth={false}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  label="Type"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={type}
                  onChange={handleChangeType}
                >
                  <option value="Législative">Législative</option>
                  <option value="Municipale">Municipale</option>
                  <option value="Présidentielle">Présidentielle</option>
                  {/* <option value="Régionale">Régionale</option> */}
                </TextField>
              </Grid>

              <Grid item xs={6}>
                <TextField
                  margin="normal"
                  label="Année"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={annee}
                  onChange={handleChangeAnnee}
                >
                  {/* {listeAnnee().map(option => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))} */}
                  <option value="" />
                  {type === 'Législative' && <option value="2021">2021</option>}
                  {type === 'Présidentielle' && (
                    <option value="2020">2020</option>
                  )}
                  {type === 'Municipale' && <option value="2018">2018</option>}
                  {type === 'Législative' && <option value="2016">2016</option>}
                  {type === 'Présidentielle' && (
                    <option value="2015">2015</option>
                  )}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  margin="normal"
                  label="CEL"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={cec}
                  onChange={handleChangeCec}
                >
                  <option value="NULL" />
                  {cecs &&
                    cecs.map(option => (
                      <option key={option.code} value={option.code}>
                        {option.nom}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  margin="normal"
                  label="Département"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={dp}
                  onChange={handleChangeDp}
                >
                  <option value="NULL" />
                  {deps &&
                    deps.map(option => (
                      <option key={option.code} value={option.code}>
                        {option.nom}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  label="Lieu de vote"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={lv}
                  onChange={handleChangeLv}
                >
                  <option value="NULL" />
                  {listeLv.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.nom}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        {fetching && (
          <Grid item xs={12}>
            <LinearProgress
              classes={{
                colorPrimary: 'primary'
              }}
            />
          </Grid>
        )}
        <Card style={{ marginBottom: 10 }}>
          <CardContent style={{ backgroundColor: colors.lightBlue[50] }}>
            <ResultatInfos resultat={resultat} />
          </CardContent>
        </Card>
        <Divider />
        <Card style={{ marginBottom: 10 }}>
          <CardContent>
            <Grid container spacing={3} style={{ marginTop: 20 }}>
              {resultat &&
                resultat.resultatsCandidat &&
                resultat.resultatsCandidat.map((resG, i) => (
                  <Grid key={'resG' + i} item xs={6} sm={4}>
                    <ResultatCandidat resultat={resultat} resultatCand={resG} />
                  </Grid>
                ))}
            </Grid>
          </CardContent>
        </Card>
      </Container>
    </Page>
  );
};

export default RapportView;
