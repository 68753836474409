import React, { PureComponent } from 'react';
import Toast from './Toast.component';
import { withStyles } from '@material-ui/core';

const styles = {
  root: {
    zIndex: '1000000',
    position: 'fixed',
    bottom: 0,
    left: 0,
    width: '100%',
    padding: '0 8px'
  },
  wrapper: {
    position: 'relative',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  }
};

class Toaster extends PureComponent {
  constructor(props) {
    super(props);
    this.deleteToast = this.deleteToast.bind(this);
  }

  deleteToast(api) {
    this.props.clearApiMessage(api);
  }

  render() {
    const { classes, toasts } = this.props;
    if (Object.keys(toasts).length === 0) return null;
    return (
      <div className={classes.root}>
        <div className={classes.wrapper}>
          {toasts.map(toast => (
            <Toast
              key={toast.api}
              api={toast.api}
              delete={this.deleteToast}
              message={toast.message}
              success={toast.success}
            />
          ))}
        </div>
      </div>
    );
  }
}

export default withStyles(styles)(Toaster);
