import { push } from 'connected-react-router';
import {
  LOGIN_USER,
  LOGOUT_USER,
  LIRE_IDENTIFIANTS,
  SAVE_IDENTIFIANTS,
  LISTER_LIEUX_VOTE,
  LISTER_BUREAUX_VOTE,
  ENREGISTRER_LIEUX_VOTE,
  INVITER_UTILISATEUR,
  LISTER_CEC,
  LISTER_DEPARTEMENT_POLITIQUE
} from './Auth.constants';
import { getRefreshToken, isTokenValid, getTokenDate } from './Auth.selectors';
import { fetchApiAction } from 'src/setup/Api/api';
import { requestDashboardData } from '../reports/Dashboard.actions';

function storeUserFromAPI(data) {
  return function(dispatch) {
    dispatch({
      type: LOGIN_USER,
      identifiant: data.identifiant,
      roles: data.roles,
      nomAffichable: data.nomAffichable,
      token: {
        value: data.token,
        expirationDate: data.dateExpirationJeton
      },
      refreshToken: data.refreshToken
    });
    dispatch(requestDashboardData());
    dispatch(listerLieuxVote());
  };
}

export function authenticate() {
  return function(dispatch, getState) {
    const state = getState();
    if (!getTokenDate(state)) {
      return false;
    }
    if (!isTokenValid(state)) {
      dispatch(refreshToken());
    }
    dispatch(requestDashboardData());
  };
}

export function refreshToken() {
  return function(dispatch, getState) {
    const token = getRefreshToken(getState());
    if (!token) {
      dispatch(push('/login'));
      return false;
    }

    dispatch(
      fetchApiAction(
        'auth:refreshToken',
        { url: token },
        storeUserFromAPI,
        function() {
          return function(dispatch) {
            dispatch(push('/login'));
          };
        }
      )
    );
  };
}

export function loginUser(user) {
  const body = {
    identifiant: user.identifiant,
    password: user.password
  };

  return fetchApiAction('auth:login', { body }, storeUserFromAPI);
}

export function lireIdentifiants() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('identifiants:lire', {}, result => ({
        type: LIRE_IDENTIFIANTS,
        data: result
      }))
    );
  };
}

export function enregistrerIdentifiant(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'identifiants:save',
        { body: payload },
        result => ({
          type: SAVE_IDENTIFIANTS,
          data: result
        }),
        null,
        "L'identifiant a été correctement créé"
      )
    );
  };
}

export function inviterUtilisateur(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'utilisateur:inviter',
        { body: payload },
        result => ({
          type: INVITER_UTILISATEUR,
          data: result
        }),
        null,
        'Le compte a été correctement créé'
      )
    );
  };
}

export function listerLieuxVote() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('lieuxVote:lister', {}, result => ({
        type: LISTER_LIEUX_VOTE,
        data: result
      }))
    );
  };
}

export function listerBureauxVote() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('bureauxVote:lister', {}, result => ({
        type: LISTER_BUREAUX_VOTE,
        data: result
      }))
    );
  };
}

export function enregistrerLieuxVote(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'lieuxVote:enregistrer',
        { body: payload },
        result => ({
          type: ENREGISTRER_LIEUX_VOTE,
          data: result
        }),
        null,
        'La modification a bien été prise en compte'
      )
    );
  };
}

export function listerCec() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('cec:lister', {}, result => ({
        type: LISTER_CEC,
        data: result
      }))
    );
  };
}

export function listerDepartementPolitique() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('dp:lister', {}, result => ({
        type: LISTER_DEPARTEMENT_POLITIQUE,
        data: result
      }))
    );
  };
}

export function logoutUser() {
  return {
    type: LOGOUT_USER
  };
}
