import { connect } from 'react-redux';
import IdentifiantsView from './IdentifiantsView.component';
import { lireIdentifiants } from '../Auth.actions';
import { getIdentifiants } from '../Auth.selectors';
import { getIsFetching, getIsFetched } from 'src/setup/Api/Api.selectors';

function mapStateToProps(state) {
  return {
    identifiants: getIdentifiants(state),
    fetching: getIsFetching(state, 'identifiants:lire'),
    fetched: getIsFetched(state, 'identifiants:lire')
  };
}

const mapDispatchToProps = {
  lireIdentifiants
};

export default connect(mapStateToProps, mapDispatchToProps)(IdentifiantsView);
