import React, { Component } from 'react';
import { withStyles } from '@material-ui/core';
import PropTypes from 'prop-types';

import SuccessIcon from '@material-ui/icons/CheckCircle';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

const transitionTime = 200;
const displayTime = 5000;

const styles = {
  root: {
    display: 'flex',
    alignItems: 'center',
    border: '2px solid transparent',
    borderRadius: '2px',
    color: '#fff',
    fontWeight: 'bold',
    fontFamily: 'Quicksand',
    margin: '8px 0',
    padding: '8px 16px',
    maxWidth: '100%',
    position: 'relative',
    transition: `all ${transitionTime / 1000}s ease-out`,
    transform: 'translateZ(0)',
    opacity: 0,
    right: '-50%',
    width: 'fit-content',
    '@media (max-width: 960px)': {
      right: 0,
      width: '100%'
    },
    '&.unfolded': {
      right: 0,
      opacity: 0.95,
      '&:hover': {
        opacity: 0.7,
        cursor: 'pointer'
      }
    }
  },
  info: {
    backgroundColor: '#757575'
  },
  success: {
    backgroundColor: '#43a047'
  },
  error: {
    backgroundColor: '#d32f2f'
  },
  content: {
    marginLeft: 8
  }
};

const getStatusIcon = statusCode => {
  switch (statusCode) {
    case 'success':
      return <SuccessIcon />;
    case 'error':
      return <ErrorIcon />;
    default:
      return <InfoIcon />;
  }
};

const getStatusCode = success => {
  if (success) {
    return 'success';
  } else {
    return 'error';
  }
};

class Toast extends Component {
  constructor(props) {
    super(props);
    this.toast = React.createRef();
    this.handleClick = this.handleClick.bind(this);
  }

  shouldComponentUpdate() {
    // ignore every updates potentially coming from the outside
    return false;
  }
  componentDidMount() {
    setTimeout(() => {
      if (this.toast.current) {
        // Really quick mount / dismount can cause DOM node to be undefined
        this.toast.current.classList.add('unfolded');
      }
    }, 10); // fix for clear transitions
    this.timeout = setTimeout(() => {
      // update itself
      this.forceUpdate();
    }, displayTime);
  }
  componentDidUpdate() {
    this.foldAndDelete();
  }
  componentWillUnmount() {
    clearTimeout(this.timeout); // avoid no-op
  }

  foldAndDelete() {
    if (this.toast.current) {
      // Really quick mount / dismount can cause DOM node to be undefined
      this.toast.current.classList.remove('unfolded');
    }
    this.timeout = setTimeout(() => {
      this.props.delete(this.props.api);
    }, transitionTime);
  }

  handleClick() {
    clearTimeout(this.timeout); // avoid no-op
    this.foldAndDelete();
  }

  render() {
    const { message, classes, success } = this.props;

    const statusCode = getStatusCode(success);
    return (
      <div
        ref={this.toast}
        className={`${classes.root} ${classes[statusCode]}`}
        onClick={this.handleClick}
      >
        {getStatusIcon(statusCode)}
        <p className={classes.content}>{message}</p>
      </div>
    );
  }
}

Toast.propTypes = {
  api: PropTypes.string,
  delete: PropTypes.func,
  message: PropTypes.string,
  success: PropTypes.bool
};

export default withStyles(styles)(Toast);
