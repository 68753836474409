import React, { useState, useEffect } from 'react';
import moment from 'moment';
import {
  Box,
  Container,
  makeStyles,
  LinearProgress,
  Button
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
import Toolbar from './Toolbar';
import { CSVDownload } from 'react-csv';
import { getConfig } from 'src/setup/platform';

const baseApi = getConfig('apiUrl');

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const defautlFilter = {
  numero: '',
  nom: '',
  nomJeuneFille: '',
  prenom: '',
  lieuNaissance: '',
  dateNaissance: '',
  numeroTelephone: '',
  partisant: '',
  profession: '',
  trancheAge: '',
  lv: '',
  bv: '',
  majRecente: '',
  statut: '',
  login: '',
  dp: '',
  contentieux2023: '',
  nouveauLEP2023: '',
  association: '',
  aVote: ''
};

const ElecteurListView = ({
  rechercherElecteur,
  electeurs,
  fetching,
  token,
  userIsAdmin
}) => {
  const classes = useStyles();

  const [pageNumero, setPageNumero] = useState(0);
  const [filtre, setFiltre] = useState(defautlFilter);
  const [downloadData, setDownloadData] = useState([]);
  const [proceedDownload, setProceedDownload] = useState(false);

  const [openSms, setOpenSms] = useState(false);

  useEffect(() => {
    if (!electeurs) {
      rechercherElecteur({ pageNumero: 0, criteres: [] });
    }
  }, [electeurs, rechercherElecteur]);

  useEffect(() => {
    if (proceedDownload) {
      setProceedDownload(false);
    }
  }, [downloadData, proceedDownload]);

  const lancerRecherche = filtreRecherche => {
    rechercherElecteur(getComputedFilter(filtreRecherche));
  };

  const getComputedFilter = filtreRecherche => {
    let filter = {
      pageNumero: filtreRecherche.pageNumero || 0,
      criteres: [
        { reference: 'critere', valeur: filtreRecherche.critere || '' },
        { reference: 'electeur.numero', valeur: filtreRecherche.numero || '' },
        {
          reference: 'electeur.numeroTelephone',
          valeur:
            filtreRecherche.numeroTelephone !== ''
              ? filtreRecherche.numeroTelephone
              : null
        },
        { reference: 'electeur.lv', valeur: filtreRecherche.lv || '' },
        { reference: 'electeur.nom', valeur: filtreRecherche.nom || '' },
        { reference: 'electeur.prenom', valeur: filtreRecherche.prenom || '' },
        {
          reference: 'electeur.nomJeuneFille',
          valeur: filtreRecherche.nomJeuneFille || ''
        },
        {
          reference: 'electeur.lieuNaissance',
          valeur: filtreRecherche.lieuNaissance || ''
        },
        { reference: 'electeur.bv', valeur: filtreRecherche.bv || '' },
        {
          reference: 'electeur.dateNaissance',
          valeur:
            filtreRecherche.dateNaissance !== ''
              ? filtreRecherche.dateNaissance
              : null
        },
        {
          reference: 'electeur.profession',
          valeur: filtreRecherche.profession || ''
        },
        {
          reference: 'electeur.trancheAge',
          valeur: filtreRecherche.trancheAge || ''
        },
        {
          reference: 'electeur.dp',
          valeur: filtreRecherche.dp || ''
        },
        {
          reference: 'electeur.statut',
          valeur: filtreRecherche.statut || ''
        },
        {
          reference: 'electeur.login',
          valeur: filtreRecherche.login || ''
        },
        {
          reference: 'electeur.majRecente',
          valeur:
            filtreRecherche.majRecente !== ''
              ? filtreRecherche.majRecente
              : null
        },
        {
          reference: 'electeur.contentieux2023',
          valeur:
            filtreRecherche.contentieux2023 !== ''
              ? filtreRecherche.contentieux2023
              : null
        },
        {
          reference: 'electeur.aVote',
          valeur: filtreRecherche.aVote !== '' ? filtreRecherche.aVote : null
        },
        {
          reference: 'electeur.nouveauLEP2023',
          valeur:
            filtreRecherche.nouveauLEP2023 !== ''
              ? filtreRecherche.nouveauLEP2023
              : null
        },
        {
          reference: 'electeur.association',
          valeur: filtreRecherche.association || ''
        }
      ]
    };

    return filter;
  };

  const lancerRecherchePagination = page => {
    lancerRecherche({ ...filtre, pageNumero: page });
  };

  const headers = [
    { label: 'Lieu de vote', key: 'lieuVoteDTO.nom' },
    { label: 'Bureau de vote', key: 'bureauVoteDTO.nom' },
    { label: "Numéro d'électeur", key: 'numero' },
    { label: 'Nom', key: 'nom' },
    { label: 'Prénoms', key: 'prenom' },
    { label: 'Nom de jeune fille', key: 'nomJeuneFille' },
    { label: 'Date de naissance', key: 'dateNaissance' },
    { label: 'Lieu de naissance', key: 'lieuNaissance' },
    { label: 'Sexe', key: 'sexe' },
    { label: 'Téléphone', key: 'contact' },
    { label: 'Profession', key: 'profession' },
    { label: 'Nom Père', key: 'nomPere' },
    { label: 'Prénom Père', key: 'prenomPere' },
    { label: 'Nom mère', key: 'nomMere' },
    { label: 'Prénom mère', key: 'prenomMere' },
    { label: 'Statut', key: 'statut' },
    { label: 'Motif du contentieux', key: 'motifContentieux' },
    { label: 'Association', key: 'association' },
    { label: 'Origine', key: 'origine' }
  ];

  const handleDownload = event => {
    const fil = getComputedFilter({ ...filtre, pageNumero: -1 });

    fetch(`${baseApi}/electeur/rechercher`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
        Accept: 'application/json'
      },
      body: JSON.stringify(fil)
    })
      .then(response => response.json())
      .then(res => {
        const data = res.body.content.map(row => ({
          ...row,
          dateNaissance: moment(row.dateNaissance).format('DD/MM/YYYY')
        }));
        setDownloadData(data);
        setProceedDownload(true);
      });
  };

  return (
    <Page className={classes.root} title="Electeurs">
      <Container maxWidth={false}>
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}

        <Toolbar
          lancerRecherche={lancerRecherche}
          filtre={filtre}
          setFiltre={setFiltre}
        />

        {userIsAdmin && (
          <div style={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
            {electeurs && electeurs.totalElements < 50000 && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={handleDownload}
                >
                  Exporter le résultat
                </Button>
                {proceedDownload && (
                  <CSVDownload
                    data={downloadData}
                    headers={headers}
                    filename={'electeurs.csv'}
                    separator={';'}
                    target="_blank"
                  />
                )}
              </>
            )}

            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpenSms(true)}
              style={{ marginLeft: 10 }}
            >
              Envoyer SMS
            </Button>
          </div>
        )}
        <Box mt={3}>
          <Results
            electeurs={electeurs}
            lancerRecherche={lancerRecherchePagination}
            pageNumero={pageNumero}
            setPageNumero={setPageNumero}
            openSms={openSms}
            setOpenSms={setOpenSms}
          />
        </Box>
      </Container>
    </Page>
  );
};

export default ElecteurListView;
