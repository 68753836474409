import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  makeStyles,
  TextField,
  Avatar,
  InputAdornment,
  MenuItem
} from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import {
  listerBureauxVote,
  listerLieuxVote
} from 'src/views/auth/Auth.actions';
import { getBureauxVote, getLieuxVote } from 'src/views/auth/Auth.selectors';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  }
});

const verifierSaisie = (values, index, newValue) => {
  if (
    values.votant === '' ||
    values.bulletinBlanc === '' ||
    values.bulletinNul === ''
  ) {
    return false;
  }

  let somme = parseInt(values.bulletinBlanc) + parseInt(values.bulletinNul);

  values.resultatsCandidat.forEach((value, i) => {
    if (value.voix !== '') {
      somme =
        somme +
        parseInt(i === index ? (newValue !== '' ? newValue : '0') : value.voix);
    }
  });

  return parseInt(values.votant) === somme;
};

const sommeVoixFct = resultatsCandidat => {
  let somme = 0;
  resultatsCandidat.forEach(value => {
    if (value.voix !== '') {
      somme = somme + parseInt(value.voix);
    }
  });
  return somme;
};

const Resultat = ({ resultats, save, showBv }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const bureauxVote = useSelector(state => getBureauxVote(state));
  const lieuxVote = useSelector(state => getLieuxVote(state));

  const [saisieOk, setSaisieOk] = useState(true);
  const [votantOk, setVotantOk] = useState(true);

  useEffect(() => {
    if (!bureauxVote && showBv) {
      dispatch(listerBureauxVote());
    }
  }, [bureauxVote, showBv, dispatch]);

  useEffect(() => {
    if (!lieuxVote && showBv) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVote, showBv, dispatch]);

  if (!resultats) {
    return null;
  }

  return (
    <Formik
      initialValues={{
        resultatsCandidat: resultats.resultatsCandidat
          ? resultats.resultatsCandidat
          : [],
        suffrageExprime: resultats.suffrageExprime
          ? resultats.suffrageExprime
          : '',
        bulletinBlanc: resultats.bulletinBlanc ? resultats.bulletinBlanc : '',
        bulletinNul: resultats.bulletinNul ? resultats.bulletinNul : '',
        votant: resultats.votant ? resultats.votant : '',
        inscrits: resultats.inscrits,
        bureauVoteDTO: resultats.bureauVoteDTO ? resultats.bureauVoteDTO : {},
        codeLv: ''
      }}
      validationSchema={Yup.object().shape({
        resultatsCandidat: Yup.array(),
        suffrageExprime: Yup.number(),
        bulletinBlanc: Yup.number().required('Ce champ est obligatoire'),
        bulletinNul: Yup.number().required('Ce champ est obligatoire'),
        votant: Yup.number().required('Ce champ est obligatoire')
      })}
      onSubmit={values => {
        save({
          ...values,
          suffrageExprime: values.votant - values.bulletinNul
        });
      }}
    >
      {({ setFieldValue, handleSubmit, values, handleChange, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Veuillez renseigner les résultats pour chaque parti politique"
              title="Saisie des Résultats"
            />
            <Divider />
            <CardContent>
              <Grid container wrap="wrap">
                <Grid className={classes.item} item xs={12}>
                  <Grid container spacing={3} wrap="wrap">
                    {!showBv && !saisieOk && (
                      <Grid className={classes.item} item xs={12}>
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          severity="error"
                        >
                          La somme de toutes les voix (Bulletin nul et blanc +
                          la somme des voix de tous les candidats) doit être
                          égale au nombre total de votants :{' '}
                          <b>{values.votant}</b>
                          <br />
                          Veuillez compléter la saisir avant de la soumettre.
                        </MuiAlert>
                      </Grid>
                    )}
                    {!showBv && !votantOk && (
                      <Grid className={classes.item} item xs={12}>
                        <MuiAlert
                          elevation={6}
                          variant="filled"
                          severity="error"
                        >
                          Le nombre total de votants doit être inférieur au
                          nombre d'inscrits : <b>{values.inscrits}</b>
                          <br />
                          Veuillez modifier le nombre!
                        </MuiAlert>
                      </Grid>
                    )}
                    {showBv && (
                      <>
                        <Grid item xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Lieu de vote"
                            name="codeLv"
                            onChange={handleChange}
                            value={values.codeLv}
                            variant="outlined"
                            required
                          >
                            <MenuItem value={null}>
                              --- Sélectionner un LV ---
                            </MenuItem>
                            {lieuxVote &&
                              lieuxVote.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.nom}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid item xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Bureau de vote"
                            name="bureauVoteDTO.code"
                            onChange={handleChange}
                            value={values.bureauVoteDTO.code}
                            variant="outlined"
                            required
                            disabled={!values.codeLv}
                          >
                            <MenuItem value={null}>
                              --- Sélectionner un BV ---
                            </MenuItem>
                            {bureauxVote &&
                              bureauxVote
                                .filter(
                                  bv => bv.lieuVote.code === values.codeLv
                                )
                                .map(option => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.nom}
                                  </MenuItem>
                                ))}
                          </TextField>
                        </Grid>
                      </>
                    )}
                    <Grid className={classes.item} item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Votants"
                        name="votant"
                        value={values.votant}
                        type="number"
                        variant="outlined"
                        required
                        onChange={event => {
                          setFieldValue('votant', event.target.value);
                          if (showBv) {
                            setVotantOk(
                              event.target.value !== ''
                                ? parseInt(event.target.value) <=
                                    parseInt(values.inscrits)
                                : false
                            );

                            if (event.target.value !== '') {
                              let sommeVoix = sommeVoixFct(
                                values.resultatsCandidat
                              );
                              setSaisieOk(
                                values.bulletinBlanc !== '' &&
                                  values.bulletinNul !== ''
                                  ? parseInt(event.target.value) ===
                                      sommeVoix +
                                        parseInt(values.bulletinBlanc) +
                                        parseInt(values.bulletinNul)
                                  : false
                              );
                            }
                          }
                        }}
                      />
                    </Grid>
                    <Grid className={classes.item} item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Bulletins nuls"
                        name="bulletinNul"
                        value={values.bulletinNul}
                        type="number"
                        variant="outlined"
                        required
                        onChange={event => {
                          setFieldValue('bulletinNul', event.target.value);
                          if (showBv && event.target.value !== '') {
                            let sommeVoix = sommeVoixFct(
                              values.resultatsCandidat
                            );
                            setSaisieOk(
                              values.votant !== '' &&
                                values.bulletinBlanc !== ''
                                ? parseInt(values.votant) ===
                                    sommeVoix +
                                      parseInt(values.bulletinBlanc) +
                                      parseInt(event.target.value)
                                : false
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid className={classes.item} item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Bulletins blancs"
                        name="bulletinBlanc"
                        value={values.bulletinBlanc}
                        type="number"
                        variant="outlined"
                        required
                        onChange={event => {
                          setFieldValue('bulletinBlanc', event.target.value);
                          if (showBv && event.target.value !== '') {
                            let sommeVoix = sommeVoixFct(
                              values.resultatsCandidat
                            );
                            setSaisieOk(
                              values.votant !== '' && values.bulletinNul !== ''
                                ? parseInt(values.votant) ===
                                    sommeVoix +
                                      parseInt(values.bulletinNul) +
                                      parseInt(event.target.value)
                                : false
                            );
                          }
                        }}
                      />
                    </Grid>
                    <Grid className={classes.item} item xs={12} sm={6}>
                      <TextField
                        fullWidth
                        label="Suffrage exprimé"
                        name="suffrageExprime"
                        value={
                          values.votant !== '' && values.bulletinNul !== ''
                            ? values.votant - values.bulletinNul
                            : ''
                        }
                        type="number"
                        variant="outlined"
                        onChange={handleChange}
                        disabled
                      />
                    </Grid>

                    {resultats &&
                      resultats.resultatsCandidat &&
                      resultats.resultatsCandidat.map((resultat, index) => (
                        <Grid
                          className={classes.item}
                          key={index}
                          item
                          xs={12}
                          sm={6}
                        >
                          <TextField
                            fullWidth
                            label={resultat.candidat}
                            name={`resultatsCandidat[${index}].voix`}
                            value={
                              values.resultatsCandidat[index]
                                ? values.resultatsCandidat[index].voix
                                : ''
                            }
                            required
                            type="number"
                            variant="outlined"
                            onChange={event => {
                              setFieldValue(
                                `resultatsCandidat[${index}].voix`,
                                event.target.value
                              );
                              setFieldValue(
                                `resultatsCandidat[${index}].candidat`,
                                resultat.candidat
                              );
                              setFieldValue(
                                `resultatsCandidat[${index}].numeroDossier`,
                                resultat.numeroDossier
                              );
                              setSaisieOk(
                                verifierSaisie(
                                  values,
                                  index,
                                  event.target.value
                                )
                              );
                            }}
                            InputProps={{
                              startAdornment: (
                                <InputAdornment position="start">
                                  <Avatar
                                    alt="Candidat"
                                    src={resultat.logo}
                                    variant="square"
                                    className={classes.logo}
                                  />
                                </InputAdornment>
                              )
                            }}
                          />
                        </Grid>
                      ))}
                    {resultats &&
                      resultats.resultatsCandidatSaisie &&
                      resultats.resultatsCandidatSaisie.map(
                        (resultat, index) => (
                          <Grid
                            className={classes.item}
                            key={index}
                            item
                            xs={12}
                            sm={6}
                          >
                            <TextField
                              fullWidth
                              label={resultat.candidat}
                              name={`resultatsCandidat[${index}].voix`}
                              value={
                                values.resultatsCandidat[index]
                                  ? values.resultatsCandidat[index].voix
                                  : ''
                              }
                              required
                              type="number"
                              variant="outlined"
                              onChange={event => {
                                setFieldValue(
                                  `resultatsCandidat[${index}].voix`,
                                  event.target.value
                                );
                                setFieldValue(
                                  `resultatsCandidat[${index}].candidat`,
                                  resultat.candidat
                                );
                                setFieldValue(
                                  `resultatsCandidat[${index}].numeroDossier`,
                                  resultat.numeroDossier
                                );
                              }}
                              InputProps={{
                                startAdornment: (
                                  <InputAdornment position="start">
                                    <Avatar
                                      alt="Candidat"
                                      src={resultat.logo}
                                      variant="square"
                                      className={classes.logo}
                                    />
                                  </InputAdornment>
                                )
                              }}
                            />
                          </Grid>
                        )
                      )}
                  </Grid>
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                variant="contained"
                type="submit"
                disabled={!showBv && (!saisieOk || !votantOk || !isValid)}
              >
                Enregister
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

Resultat.propTypes = {
  className: PropTypes.string,
  resultats: PropTypes.object,
  save: PropTypes.func,
  showBv: PropTypes.bool
};

export default Resultat;
