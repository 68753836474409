import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import EmojiPeopleIcon from '@material-ui/icons/EmojiPeople';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.green[600],
    height: 56,
    width: 56
  },
  avatarWhite: {
    backgroundColor: colors.common.white,
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  whiteIcon: {
    color: colors.common.white
  },
  cyanIcon: {
    color: colors.cyan[600]
  }
}));

const TotalVotants = ({ value, titre, className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              gutterBottom
              variant="h6"
              style={{ color: colors.blueGrey[600] }}
            >
              {titre}
            </Typography>
            <Typography style={{ color: colors.blueGrey[900] }} variant="h3">
              {value}
            </Typography>
          </Grid>
          <Grid item>
            <Avatar className={classes.avatar}>
              <EmojiPeopleIcon className={classes.whiteIcon} />
            </Avatar>
          </Grid>
        </Grid>
        <Box mt={2} display="flex" alignItems="center">
          <DoneAllIcon className={classes.differenceIcon} />
        </Box>
      </CardContent>
    </Card>
  );
};

TotalVotants.propTypes = {
  className: PropTypes.string,
  value: PropTypes.any,
  titre: PropTypes.string
};

export default TotalVotants;
