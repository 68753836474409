import React, { useEffect } from 'react';
import {
  Box,
  Container,
  makeStyles,
  Button,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import Results from './Results';
// import ConfirmDialog from 'src/components/Dialog/ConfirmDialog.component';
import { CSVLink } from 'react-csv';
import AddIdentifiant from './AddIdentifiant';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const IdentifiantsView = ({
  lireIdentifiants,
  identifiants,
  fetching,
  fetched
}) => {
  const classes = useStyles();

  // const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!identifiants && !fetching && !fetched) {
      lireIdentifiants();
    }
  });

  const headers = [
    { label: 'Lieu de vote', key: 'lieuxVote' },
    { label: 'Identifiant', key: 'identifiant' },
    { label: 'Mot de passe', key: 'password' },
    { label: 'Nom', key: 'nom' },
    { label: 'Prénoms', key: 'prenom' },
    { label: 'Téléphone', key: 'telephone' },
    { label: 'Profil', key: 'profil' }
  ];

  return (
    <Page className={classes.root} title="Electeurs">
      <Container maxWidth={false}>
        <AddIdentifiant />
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}
        {identifiants && identifiants.length > 0 && (
          <div style={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
            <CSVLink
              data={identifiants}
              headers={headers}
              filename={'identifiants.csv'}
              enclosingCharacter={`'`}
              separator={';'}
              target="_blank"
            >
              <Button color="primary" variant="contained">
                Exporter les identifiants
              </Button>
            </CSVLink>
          </div>
        )}
        <Box mt={3}>
          <Results identifiants={identifiants} />
        </Box>
      </Container>
      {/* <ConfirmDialog
        open={open}
        handleClose={() => setOpen(false)}
        handleValidate={() => {
          genererIdentifiants();
          setOpen(false);
        }}
        title="Êtes-vous sûr de vouloir générer les identifiants ?"
        description="La génération de ces identifiants annulera tous les codes précédemment générés."
      /> */}
    </Page>
  );
};

export default IdentifiantsView;
