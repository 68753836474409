import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  makeStyles,
  Button,
  LinearProgress,
  IconButton,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import BlockIcon from '@material-ui/icons/Block';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Page from 'src/components/Page';
import { CSVLink } from 'react-csv';
import { getElecteursAvalider } from './Electeur.selectors';
import { listerElecteurAvalider, validerElecteur } from './Electeur.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import { isUserIsAdmin } from '../auth/Auth.selectors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  barre: { textDecoration: 'line-through' },
  normal: { textDecoration: 'none' }
}));

const ElecteurAvaliderView = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const electeurs = useSelector(state => getElecteursAvalider(state));
  const userIsAdmin = useSelector(state => isUserIsAdmin(state));

  const fetching = useSelector(state =>
    getIsFetching(state, 'electeur:avalider')
  );

  const fetchingC = useSelector(state =>
    getIsFetching(state, 'electeur:controler')
  );

  useEffect(() => {
    if (!electeurs) {
      dispatch(listerElecteurAvalider());
    }
  }, [electeurs, dispatch]);

  const headers = [
    { label: 'Nom', key: 'nom' },
    { label: 'Prénoms', key: 'prenom' },
    { label: 'Nom de jeune fille', key: 'nomJeuneFille' },
    { label: 'Date de naissance', key: 'dateNaissance' },
    { label: 'Lieu de naissance', key: 'lieuNaissance' },
    { label: 'Sexe', key: 'sexe' },
    { label: 'Téléphone', key: 'contact' },
    { label: 'Profession', key: 'profession' },
    { label: 'Nom Père', key: 'nomPere' },
    { label: 'Prénom Père', key: 'prenomPere' },
    { label: 'Nom mère', key: 'nomMere' },
    { label: 'Prénom mère', key: 'prenomMere' },
    { label: 'Statut', key: 'statut' }
  ];

  return (
    <Page className={classes.root} title="Saisies à contrôler">
      <Container maxWidth={false}>
        {(fetching || fetchingC) && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}
        {userIsAdmin && electeurs && electeurs.length > 0 && (
          <div style={{ textAlign: 'center', width: '100%', marginTop: 10 }}>
            <CSVLink
              data={electeurs}
              headers={headers}
              filename={'electeursSaisis.csv'}
              enclosingCharacter={`'`}
              separator={';'}
              target="_blank"
            >
              <Button color="primary" variant="contained">
                Exporter le résultat
              </Button>
            </CSVLink>
          </div>
        )}
        <Box mt={3}>
          {electeurs && electeurs.length > 0 && (
            <Card>
              <PerfectScrollbar>
                <Box>
                  <Typography style={{ margin: 20, fontStyle: 'italic' }}>
                    Nombre de saisies trouvées: {electeurs.length}
                  </Typography>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Nom Prénoms</TableCell>
                        <TableCell>Date et lieu naissance</TableCell>
                        <TableCell>Sexe</TableCell>
                        <TableCell>Téléphone</TableCell>
                        <TableCell>Profession</TableCell>
                        <TableCell>Père</TableCell>
                        <TableCell>mère</TableCell>
                        <TableCell>Statut</TableCell>
                        <TableCell>Actions</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {electeurs.map(electeur => (
                        <TableRow hover key={electeur.id}>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >
                            {electeur.nomJeuneFille
                              ? `${electeur.nomJeuneFille} ${electeur.prenom} ép. ${electeur.nom}`
                              : `${electeur.nom} ${electeur.prenom}`}
                          </TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >{`né le ${moment(electeur.dateNaissance).format(
                            'DD/MM/YYYY'
                          )} à ${electeur.lieuNaissance}`}</TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >
                            {electeur.sexe}
                          </TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >
                            {electeur.contact}
                          </TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >
                            {electeur.profession}
                          </TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >{`${electeur.nomPere} ${electeur.prenomPere}`}</TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >{`${electeur.nomMere} ${electeur.prenomMere}`}</TableCell>
                          <TableCell
                            className={
                              electeur.rejette === true
                                ? classes.barre
                                : classes.normal
                            }
                          >
                            {electeur.statut}
                          </TableCell>
                          <TableCell>
                            {electeur.rejette === false && (
                              <IconButton
                                color="secondary"
                                onClick={() => {
                                  dispatch(validerElecteur(electeur.id, false));
                                }}
                              >
                                <CheckCircleOutlineIcon />
                              </IconButton>
                            )}
                            {electeur.rejette === false && (
                              <IconButton
                                color="primary"
                                onClick={() => {
                                  dispatch(validerElecteur(electeur.id, true));
                                }}
                              >
                                <BlockIcon />
                              </IconButton>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </Box>
              </PerfectScrollbar>
            </Card>
          )}
          {(!electeurs || electeurs.length === 0) && (
            <Typography component="p">Aucunes saisies trouvées...</Typography>
          )}
        </Box>
      </Container>
    </Page>
  );
};

export default ElecteurAvaliderView;
