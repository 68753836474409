import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const StatRepresentant = ({ className, datas, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={'Statistiques de saisie des représentants'} />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Lieu de vote</TableCell>
                <TableCell>Nom du représentant</TableCell>
                <TableCell>Nombre nouveaux electeurs saisis</TableCell>
                <TableCell>Nombre de nouveaux partisans saisis</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datas &&
                datas.map((data, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{data.lieuVote}</TableCell>
                    <TableCell>{data.nom}</TableCell>
                    <TableCell>{data.nbElecteurSaisis}</TableCell>
                    <TableCell>{data.nbPartisantsSaisis}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

StatRepresentant.propTypes = {
  className: PropTypes.string,
  datas: PropTypes.array
};

export default StatRepresentant;
