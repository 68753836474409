import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { routerMiddleware } from 'connected-react-router';
import { createBrowserHistory as createHistory } from 'history';
import rootReducer, { dehydrateState, hydrateState } from './setup/reducers';

export const history = createHistory();

const middlewares = [thunk, routerMiddleware(history)];
const enhancers = [];

// Redux Access to the Browser DevTools
if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}
const composedEnhancers = compose(
  applyMiddleware(...middlewares),
  ...enhancers
);

// Grab the state from a global variable injected into the server-generated HTML
const preloadedState = hydrateState(window.__PRELOADED_STATE__);

// Allow the passed state to be garbage-collected
delete window.__PRELOADED_STATE__;

const store = createStore(
  rootReducer(history),
  preloadedState,
  composedEnhancers
);

// Tell react-snap how to save Redux state
window.snapSaveState = () => ({
  __PRELOADED_STATE__: dehydrateState(store.getState())
});

export default store;
