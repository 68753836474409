import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import moment from 'moment';
import {
  Container,
  CardHeader,
  makeStyles,
  Divider,
  Card,
  CardContent,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Box
} from '@material-ui/core';
import Page from 'src/components/Page';

import { isUserIsAdmin } from 'src/views/auth/Auth.selectors';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import { getHistoriqueSms } from './Dashboard.selectors';
import { lireHistoriqueSms } from './Dashboard.actions';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const SmsView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const historiqueSms = useSelector(state => getHistoriqueSms(state));
  const fetching = useSelector(state => getIsFetching(state, 'sms:stat'));
  const userIsAdmin = useSelector(state => isUserIsAdmin(state));

  useEffect(() => {
    if (!historiqueSms) {
      dispatch(lireHistoriqueSms());
    }
  }, [historiqueSms, dispatch]);

  if (!userIsAdmin) {
    return null;
  }

  return (
    <Page className={classes.root} title="Historique SMS">
      <Container maxWidth={false}>
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}

        <Card>
          <CardContent>
            <Typography gutterBottom variant="h6">
              Solde SMS
            </Typography>
            <Typography variant="h3">
              {historiqueSms && historiqueSms.solde}
            </Typography>
          </CardContent>
        </Card>

        <Divider style={{ marginTop: 20, marginBottom: 20 }} />

        <Card>
          <CardHeader title={"Historique d'envoi"} />
          <Divider />
          <PerfectScrollbar>
            <Box>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Date d'envoi</TableCell>
                    <TableCell>Message envoyé</TableCell>
                    <TableCell>Nombre de sms envoyés</TableCell>
                    <TableCell>Envoyé par</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {historiqueSms &&
                    historiqueSms.statSms.map((stat, index) => (
                      <TableRow hover key={index}>
                        <TableCell>
                          {moment(stat.date).format('DD/MM/YYYY à HH:mm')}
                        </TableCell>
                        <TableCell>{stat.message}</TableCell>
                        <TableCell>{stat.nombre}</TableCell>
                        <TableCell>{stat.envoyePar}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>
      </Container>
    </Page>
  );
};

export default SmsView;
