import {
  RECHERCHER_ELECTEUR,
  VIDER_RESULTATS,
  ENREGISTRER_ELECTEUR,
  RESET_SELECTED_ELECTEUR,
  CONTROLER_ELECTEUR,
  ELECTEUR_A_VALIDER,
  ELECTEUR_LISTER_PROFESSION,
  MODIFIER_ELECTEUR,
  ENVOYER_SMS
} from './Electeur.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function rechercherElecteur(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction('electeur:rechercher', { body: payload }, result => ({
        type: RECHERCHER_ELECTEUR,
        data: result
      }))
    );
  };
}

export function enregistrerElecteur(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'electeur:enregistrer',
        { body: payload },
        result => ({
          type: ENREGISTRER_ELECTEUR,
          data: result
        }),
        null,
        'Votre demande a bien été prise en compte'
      )
    );
  };
}

export const viderResultatElecteur = () => {
  return {
    type: VIDER_RESULTATS
  };
};

export const resetExistingElecteur = () => {
  return {
    type: RESET_SELECTED_ELECTEUR
  };
};

export function validerElecteur(id, rejette) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'electeur:controler',
        { url: { id, rejette } },
        result => ({
          type: CONTROLER_ELECTEUR,
          data: result
        }),
        null,
        'Votre demande a bien été prise en compte'
      )
    );
  };
}

export function listerElecteurAvalider() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('electeur:avalider', {}, result => ({
        type: ELECTEUR_A_VALIDER,
        data: result
      }))
    );
  };
}

export function listerProfessions() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('electeur:professions', {}, result => ({
        type: ELECTEUR_LISTER_PROFESSION,
        data: result
      }))
    );
  };
}

export function modifierElecteur(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'electeur:modifier',
        { body: payload },
        result => ({
          type: MODIFIER_ELECTEUR,
          data: result
        }),
        null,
        'Votre demande a bien été prise en compte'
      )
    );
  };
}

export function envoyerSms(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'sms:send',
        { body: payload },
        result => ({
          type: ENVOYER_SMS,
          data: result
        }),
        null,
        'Votre sms a bien été envoyé'
      )
    );
  };
}
