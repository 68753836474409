import {
  UPDATE_DASHBOARD_DATAS,
  UPDATE_RESULTAT,
  LIRE_HISTORIQUE_SMS
} from './Dashboard.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function requestDashboardData(cec, dp, lv) {
  return function(dispatch) {
    dispatch(
      fetchApiAction('dashboard:lire', { url: { cec, dp, lv } }, result => ({
        type: UPDATE_DASHBOARD_DATAS,
        data: result
      }))
    );
  };
}

export function requestResultsData(type, annee, cec, dp, lv) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'dashboard:resultat',
        { url: { type, annee, cec, dp, lv } },
        result => ({
          type: UPDATE_RESULTAT,
          data: result
        })
      )
    );
  };
}

export function lireHistoriqueSms() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('sms:stat', {}, result => ({
        type: LIRE_HISTORIQUE_SMS,
        data: result
      }))
    );
  };
}
