import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  RECHERCHER_ELECTEUR,
  VIDER_RESULTATS,
  ENREGISTRER_ELECTEUR,
  RESET_SELECTED_ELECTEUR,
  CONTROLER_ELECTEUR,
  ELECTEUR_A_VALIDER,
  ELECTEUR_LISTER_PROFESSION,
  MODIFIER_ELECTEUR,
  ENVOYER_SMS
} from './Electeur.constants';

const initialState = {};

const ElecteurReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENREGISTRER_ELECTEUR:
      return {
        ...state,
        electeurExistant: action.data,
        listeAvaliser: undefined,
        data: undefined
      };
    case RESET_SELECTED_ELECTEUR:
      return {
        ...state,
        electeurExistant: undefined
      };
    case ENVOYER_SMS:
      return {
        ...state,
        sms: action.data
      };
    case MODIFIER_ELECTEUR:
      return {
        ...state,
        data: undefined
      };
    case RECHERCHER_ELECTEUR:
      return {
        ...state,
        data: action.data
      };
    case CONTROLER_ELECTEUR:
    case ELECTEUR_A_VALIDER:
      return {
        ...state,
        listeAvaliser: action.data
      };
    case ELECTEUR_LISTER_PROFESSION:
      return {
        ...state,
        professions: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    case VIDER_RESULTATS:
      return {
        ...state,
        data: []
      };
    default:
      return state;
  }
};

ElecteurReducer.hydrate = function() {
  return initialState;
};

export default ElecteurReducer;
