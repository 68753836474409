const defaultStatus = {
  fetching: false
};

export const getApiStatus = (state, name) => state.api[name] || defaultStatus;

export const getIsFetching = (state, name) =>
  getApiStatus(state, name).fetching;

export const getIsFetched = (state, name) => getApiStatus(state, name).success;

export const getErrorsMessages = state => {
  return Object.keys(state.api)
    .filter(name => state.api[name].message)
    .map(name => {
      return {
        api: name,
        message: state.api[name].message,
        success: state.api[name].success
      };
    });
};
