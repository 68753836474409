import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Doughnut } from 'react-chartjs-2';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    height: '100%'
  },
  logo: {
    margin: 'auto'
  },
  itemDiv: {
    textAlign: 'center',
    border: '1px solid lightgray',
    borderRadius: 5
  }
}));

const graphColors = [
  colors.blue[200],
  colors.red[800],
  colors.common.black,
  colors.purple[600],
  colors.blue[600],
  colors.orange[600],
  colors.green[600]
];

const getLabelInfos = values => {
  let details = [];
  let datas = [];
  let labels = [];
  values.forEach((element, index) => {
    details.push({
      title: element.label,
      value: element.value,
      color: graphColors[index]
    });
    datas.push(element.value);
    labels.push(element.label);
  });
  return { details, datas, labels };
};

const TranchesAges = ({ values, className, ...rest }) => {
  const classes = useStyles();

  if (!values) {
    return (
      <Card className={clsx(classes.root, className)} {...rest}>
        <CardHeader title="Repartition par tranche d'âge" />
        <Divider />
        <CardContent>
          <Typography
            gutterBottom
            variant="h5"
            style={{ color: colors.red[500], textAlign: 'center' }}
          >
            Aucunes données pour l'instant
          </Typography>
        </CardContent>
      </Card>
    );
  }

  const infos = getLabelInfos(values);

  const data = {
    datasets: [
      {
        data: infos.datas,
        backgroundColor: graphColors,
        borderWidth: 8,
        borderColor: colors.common.white,
        hoverBorderColor: colors.common.white
      }
    ],
    labels: infos.labels
  };

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title="Repartition par tranche d'âge" />
      <Divider />
      <CardContent>
        <Box position="relative">
          <Doughnut data={data} />
        </Box>
      </CardContent>
    </Card>
  );
};

TranchesAges.propTypes = {
  className: PropTypes.string,
  values: PropTypes.array
};

export default TranchesAges;
