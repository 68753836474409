import React from 'react';
import PropTypes from 'prop-types';
import {
  Divider,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Button
} from '@material-ui/core';

import Resultat from './Resultat';

const SaisieResultatPopup = ({
  open,
  handleClose,
  resultats,
  save,
  showBv
}) => {
  if (!resultats) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Saisie de résultats</DialogTitle>
      <DialogContent>
        <div>Saisie de résultats</div>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Resultat resultats={resultats} save={save} showBv={showBv} />
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Box style={{ textAlign: 'center' }}>
          <Button
            color="default"
            variant="contained"
            onClick={handleClose}
            style={{ marginRight: 20 }}
          >
            Fermer
          </Button>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

SaisieResultatPopup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired,
  resultats: PropTypes.object,
  save: PropTypes.func,
  showBv: PropTypes.bool
};

export default SaisieResultatPopup;
