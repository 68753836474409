import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  TextField,
  Typography,
  makeStyles
} from '@material-ui/core';
import Page from 'src/components/Page';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    height: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LoginView = props => {
  const classes = useStyles();
  const navigate = useNavigate();
  useEffect(() => {
    if (props.user && props.tokenValid) {
      navigate('/app/dashboard', { replace: true });
    }
  });

  return (
    <Page className={classes.root} title="Connexion">
      <Box
        display="flex"
        flexDirection="column"
        height="100%"
        justifyContent="center"
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              identifiant: '',
              password: ''
            }}
            validationSchema={Yup.object().shape({
              identifiant: Yup.string().required(
                "Veuillez saisir l'identifiant"
              ),
              password: Yup.string().required('Veuillez saisir le mot de passe')
            })}
            onSubmit={values => {
              props.loginUser({ ...values });
            }}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box mb={3} style={{ textAlign: 'center' }}>
                  <Typography color="textPrimary" variant="h2">
                    Connexion
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    Veuillez saisir vos identifiants pour accéder à la
                    plateforme
                  </Typography>
                </Box>

                <TextField
                  error={Boolean(touched.identifiant && errors.identifiant)}
                  fullWidth
                  helperText={touched.identifiant && errors.identifiant}
                  label="Identifiant"
                  margin="normal"
                  name="identifiant"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="text"
                  value={values.identifiant}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.password && errors.password)}
                  fullWidth
                  helperText={touched.password && errors.password}
                  label="Password"
                  margin="normal"
                  name="password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.password}
                  variant="outlined"
                />
                <Box my={2}>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Me connecter
                  </Button>
                </Box>
              </form>
            )}
          </Formik>
        </Container>
      </Box>
    </Page>
  );
};

export default LoginView;
