import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { enregistrerAssociation } from './Association.actions';

const AddAssociation = ({ open, handleClose, association }) => {
  const dispatch = useDispatch();

  if (!association) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {association.code
          ? "Modification d'une association"
          : "Ajout d'une association"}
      </DialogTitle>
      <DialogContent>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Formik
          initialValues={{ ...association }}
          validationSchema={Yup.object().shape({
            code: Yup.string().nullable(),
            nom: Yup.string().required(
              "Veuillez saisir le nom de l'association"
            )
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(enregistrerAssociation({ ...values }));
            handleClose();
            resetForm();
          }}
        >
          {({ handleChange, handleSubmit, values, isValid }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Nom de l'association"
                        name="nom"
                        onChange={handleChange}
                        required
                        value={values.nom}
                        variant="outlined"
                        type="text"
                      />
                    </Grid>
                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={handleClose}
                          style={{ marginRight: 20 }}
                        >
                          Annuler
                        </Button>
                        <Button
                          color="primary"
                          disabled={!isValid}
                          variant="contained"
                          type="submit"
                        >
                          Valider
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

AddAssociation.propTypes = {
  open: PropTypes.bool,
  association: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default AddAssociation;
