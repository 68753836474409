import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import { getBureauxVote, getLieuxVote } from '../Auth.selectors';
import {
  enregistrerIdentifiant,
  listerBureauxVote,
  listerLieuxVote
} from '../Auth.actions';

const AddIdentifiant = () => {
  const dispatch = useDispatch();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const bureauVotes = useSelector(state => getBureauxVote(state));

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (!bureauVotes) {
      dispatch(listerBureauxVote());
    }
  }, [bureauVotes, dispatch]);

  return (
    <Formik
      initialValues={{
        nom: '',
        prenom: '',
        telephone: '',
        identifiant: '',
        password: '',
        profil: '',
        lieuxVote: '',
        bureauVote: ''
      }}
      validationSchema={Yup.object().shape({
        nom: Yup.string().required('Veuillez saisir le nom'),
        prenom: Yup.string().required('Veuillez saisir le prénom'),
        telephone: Yup.string().nullable(),
        identifiant: Yup.string().required("Veuillez saisir l'identifiant"),
        password: Yup.string().required('Veuillez saisir le mot de passe'),
        profil: Yup.string().required('Veuillez saisir le profil'),
        lieuxVote: Yup.string(),
        bureauVote: Yup.string()
      })}
      onSubmit={(values, { resetForm }) => {
        dispatch(enregistrerIdentifiant({ ...values }));
        resetForm();
      }}
    >
      {({ handleChange, handleSubmit, values, isValid }) => (
        <form onSubmit={handleSubmit}>
          <Card>
            <CardHeader
              subheader="Veuillez saisir les informations du nouvel utilisateur"
              title="Ajout d'un nouvel utilisateur"
            />
            <Divider />
            <CardContent>
              <Grid container spacing={3} wrap="wrap">
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Nom"
                    name="nom"
                    onChange={handleChange}
                    required
                    value={values.nom}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Prénoms"
                    name="prenom"
                    onChange={handleChange}
                    required
                    value={values.prenom}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={4} xs={12}>
                  <TextField
                    fullWidth
                    label="Téléphone"
                    name="telephone"
                    onChange={handleChange}
                    required
                    value={values.telephone}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
              <Divider style={{ marginBottom: 15, marginTop: 15 }} />
              <Grid container spacing={6} wrap="wrap">
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Identifiant de connexion"
                    name="identifiant"
                    onChange={handleChange}
                    value={values.identifiant}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Mot de passe"
                    name="password"
                    onChange={handleChange}
                    type="password"
                    value={values.password}
                    variant="outlined"
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    select
                    fullWidth
                    label="Profil"
                    name="profil"
                    onChange={handleChange}
                    value={values.profil}
                    variant="outlined"
                    required
                  >
                    <MenuItem />
                    <MenuItem value="ADMIN">ADMIN</MenuItem>
                    <MenuItem value="DEPARTEMENTAL">DEPARTEMENTAL</MenuItem>
                    <MenuItem value="REPRESENTANT">REPRESENTANT</MenuItem>
                  </TextField>
                </Grid>
                {values.profil &&
                  (values.profil === 'ADMIN' ||
                    values.profil === 'DEPARTEMENTAL') && (
                    <Grid item md={6} xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Lieu de vote"
                        name="lieuxVote"
                        onChange={handleChange}
                        value={values.lieuxVote}
                        variant="outlined"
                        required
                      >
                        <MenuItem />
                        {lieuxVotes &&
                          lieuxVotes.map(option => (
                            <MenuItem key={option.code} value={option.code}>
                              {`[${option.numero}] ${option.nom}`}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                  )}
                {values.profil && values.profil === 'REPRESENTANT' && (
                  <Grid item md={6} xs={12}>
                    <TextField
                      select
                      fullWidth
                      label="Bureau de vote"
                      name="bureauVote"
                      onChange={handleChange}
                      value={values.bureauVote}
                      variant="outlined"
                      required
                    >
                      <MenuItem />
                      {bureauVotes &&
                        bureauVotes.map(option => (
                          <MenuItem key={option.code} value={option.code}>
                            {`[${option.numero}] ${option.nom}`}
                          </MenuItem>
                        ))}
                    </TextField>
                  </Grid>
                )}
              </Grid>
            </CardContent>
            <Divider />
            <Box display="flex" justifyContent="flex-end" p={2}>
              <Button
                color="primary"
                disabled={!isValid}
                variant="contained"
                type="submit"
              >
                Enregister
              </Button>
            </Box>
          </Card>
        </form>
      )}
    </Formik>
  );
};

AddIdentifiant.propTypes = {
  className: PropTypes.string,
  lieuxVotes: PropTypes.array
};

export default AddIdentifiant;
