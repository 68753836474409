import React from 'react';
import PropTypes from 'prop-types';
import { Avatar, Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 10
  },
  logo: {
    margin: 'auto'
  },
  itemDiv: {
    textAlign: 'center',
    border: '1px solid lightgray',
    borderRadius: 5
  }
}));

const ResultatTotal = ({ resultat, className, ...rest }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemDiv}>
      <Avatar
        alt="Candidat"
        src={resultat.logo}
        variant="square"
        className={classes.logo}
      />

      <Typography gutterBottom style={{ fontSize: 9 }}>
        {resultat.candidat}
      </Typography>
      <Typography variant="h3">{resultat.taux} %</Typography>
      <Typography component="p">Voix: {resultat.voix}</Typography>
    </div>
  );
};

ResultatTotal.propTypes = {
  className: PropTypes.string
};

export default ResultatTotal;
