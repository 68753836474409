import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  ENREGISTRER_ASSOCIATION,
  LISTER_ASSOCIATION,
  SUPPRIMER_ASSOCIATION
} from './Association.constants';

const initialState = {};

const AssociationReducer = (state = initialState, action) => {
  switch (action.type) {
    case ENREGISTRER_ASSOCIATION:
    case LISTER_ASSOCIATION:
    case SUPPRIMER_ASSOCIATION:
      return {
        ...state,
        data: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

AssociationReducer.hydrate = function() {
  return initialState;
};

export default AssociationReducer;
