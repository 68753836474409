import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import clsx from 'clsx';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Button,
  Box,
  Card,
  CardContent,
  TextField,
  MenuItem,
  makeStyles,
  Divider,
  Grid
} from '@material-ui/core';
import {
  getBureauxVote,
  getDepartementPolitique,
  getIdentifiants,
  getLieuxVote
} from 'src/views/auth/Auth.selectors';
import {
  lireIdentifiants,
  listerBureauxVote,
  listerDepartementPolitique,
  listerLieuxVote
} from 'src/views/auth/Auth.actions';
import { getProfessions } from '../Electeur.selectors';
import { listerProfessions } from '../Electeur.actions';
import { getAssociations } from 'src/views/association/Association.selectors';
import { listerAssociations } from 'src/views/association/Association.actions';

const useStyles = makeStyles(theme => ({
  root: {},
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  }
}));

const Toolbar = ({ className, filtre, setFiltre, lancerRecherche }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const bureauVotes = useSelector(state => getBureauxVote(state));
  const professions = useSelector(state => getProfessions(state));
  const identifiants = useSelector(state => getIdentifiants(state));
  const deps = useSelector(state => getDepartementPolitique(state));
  const associations = useSelector(state => getAssociations(state));

  useEffect(() => {
    if (!associations) {
      dispatch(listerAssociations());
    }
  }, [associations, dispatch]);

  useEffect(() => {
    if (!professions) {
      dispatch(listerProfessions());
    }
  }, [professions, dispatch]);

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (!identifiants) {
      dispatch(lireIdentifiants());
    }
  }, [identifiants, dispatch]);

  useEffect(() => {
    if (!deps) {
      dispatch(listerDepartementPolitique());
    }
  }, [deps, dispatch]);

  useEffect(() => {
    if (!bureauVotes) {
      dispatch(listerBureauxVote());
    }
  }, [bureauVotes, dispatch]);

  return (
    <div className={clsx(classes.root, className)}>
      <Box mt={3}>
        <Card>
          <CardContent>
            <Formik
              initialValues={{
                ...filtre
              }}
              validationSchema={Yup.object().shape({
                numero: Yup.string().nullable(),
                nom: Yup.string().nullable(),
                nomJeuneFille: Yup.string().nullable(),
                prenom: Yup.string().nullable(),
                lieuNaissance: Yup.string().nullable(),
                dateNaissance: Yup.string().nullable(),
                profession: Yup.string().nullable(),
                trancheAge: Yup.string().nullable(),
                numeroTelephone: Yup.boolean().nullable(),
                majRecente: Yup.boolean().nullable(),
                statut: Yup.string().nullable(),
                login: Yup.string().nullable(),
                lv: Yup.string().nullable(),
                bv: Yup.string().nullable(),
                dp: Yup.string().nullable(),
                contentieux2023: Yup.boolean().nullable(),
                nouveauLEP2023: Yup.boolean().nullable(),
                association: Yup.string().nullable(),
                aVote: Yup.boolean().nullable()
              })}
              onSubmit={values => {
                const valueToSend = {
                  ...values,
                  bv: values.lv !== '' && values.bv !== '' ? values.bv : ''
                };
                setFiltre({ pageNumero: 0, ...valueToSend });
                lancerRecherche({ pageNumero: 0, ...values });
              }}
            >
              {({ handleChange, setFieldValue, handleSubmit, values }) => (
                <form onSubmit={handleSubmit}>
                  <Card>
                    <Divider />
                    <CardContent>
                      <Grid container spacing={3} wrap="wrap">
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Nom"
                            name="nom"
                            onChange={handleChange}
                            value={values.nom}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Nom de jeune fille"
                            name="nomJeuneFille"
                            onChange={handleChange}
                            value={values.nomJeuneFille}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Prénoms"
                            name="prenom"
                            onChange={handleChange}
                            value={values.prenom}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Numéro d'électeur"
                            name="numero"
                            onChange={handleChange}
                            value={values.numero}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            helperText="Date de naissance"
                            name="dateNaissance"
                            type="date"
                            fullWidth
                            onChange={handleChange}
                            value={values.dateNaissance}
                            variant="outlined"
                          />
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            fullWidth
                            label="Lieu de naissance"
                            name="lieuNaissance"
                            onChange={handleChange}
                            value={values.lieuNaissance}
                            variant="outlined"
                          />
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Mise à jour récente"
                            name="majRecente"
                            onChange={handleChange}
                            value={values.majRecente}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value={true}>Oui</MenuItem>
                            <MenuItem value={false}>Non</MenuItem>
                          </TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Ayant un numéro de téléphone"
                            name="numeroTelephone"
                            onChange={handleChange}
                            value={values.numeroTelephone}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value={true}>Oui</MenuItem>
                            <MenuItem value={false}>Non</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Profession"
                            name="profession"
                            onChange={handleChange}
                            value={values.profession}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {professions &&
                              professions.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.value}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>
                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Tranche d'âge"
                            name="trancheAge"
                            onChange={handleChange}
                            value={values.trancheAge}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value="18_25">18 - 25 ans</MenuItem>
                            <MenuItem value="25_30">25 - 30 ans</MenuItem>
                            <MenuItem value="30_40">30 - 40 ans</MenuItem>
                            <MenuItem value="40_50">40 - 50 ans</MenuItem>
                            <MenuItem value="PLUS_50">Plus de 50 ans</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Statut"
                            name="statut"
                            onChange={handleChange}
                            value={values.statut}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value="CONTACT">Contact</MenuItem>
                            <MenuItem value="RHDP">RHDP</MenuItem>
                            <MenuItem value="SYMPHATISANT">
                              Sympathisant
                            </MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Contentieux"
                            name="contentieux2023"
                            onChange={handleChange}
                            value={values.contentieux2023}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value={true}>Oui</MenuItem>
                            <MenuItem value={false}>Non</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Nouveau sur Liste 2023"
                            name="nouveauLEP2023"
                            onChange={handleChange}
                            value={values.nouveauLEP2023}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value={true}>Oui</MenuItem>
                            <MenuItem value={false}>Non</MenuItem>
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Association"
                            name="association"
                            onChange={handleChange}
                            value={values.association}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {associations &&
                              associations.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.nom}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Utilisateurs"
                            name="login"
                            onChange={handleChange}
                            value={values.login}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {identifiants &&
                              identifiants.map(option => (
                                <MenuItem
                                  key={option.identifiant}
                                  value={option.identifiant}
                                >
                                  {`${option.nom} ${option.prenom}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Département"
                            name="dp"
                            onChange={handleChange}
                            value={values.dp}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {deps &&
                              deps.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.nom}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Lieu de vote"
                            name="lv"
                            onChange={event => {
                              setFieldValue('lv', event.target.value);
                              setFieldValue('bv', '');
                            }}
                            value={values.lv}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {lieuxVotes &&
                              lieuxVotes.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {`[${option.numero}] ${option.nom}`}
                                </MenuItem>
                              ))}
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Bureau de vote"
                            name="bv"
                            onChange={handleChange}
                            value={values.bv}
                            variant="outlined"
                            disabled={!values.lv}
                          >
                            <MenuItem value="">Tous</MenuItem>
                            {bureauVotes &&
                              bureauVotes
                                .filter(bv => bv.lieuVote.code === values.lv)
                                .map(option => (
                                  <MenuItem
                                    key={option.code}
                                    value={option.code}
                                  >
                                    {option.nom}
                                  </MenuItem>
                                ))}
                          </TextField>
                        </Grid>

                        <Grid item md={4} xs={12}>
                          <TextField
                            select
                            fullWidth
                            label="Ayant voté"
                            name="aVote"
                            onChange={handleChange}
                            value={values.aVote}
                            variant="outlined"
                          >
                            <MenuItem value="">Tous</MenuItem>
                            <MenuItem value={true}>Oui</MenuItem>
                            <MenuItem value={false}>Non</MenuItem>
                          </TextField>
                        </Grid>
                      </Grid>
                    </CardContent>
                    <Divider />
                    <Box display="flex" justifyContent="flex-end" p={2}>
                      <Button color="primary" variant="contained" type="submit">
                        Rechercher
                      </Button>
                    </Box>
                  </Card>
                </form>
              )}
            </Formik>
          </CardContent>
        </Card>
      </Box>
    </div>
  );
};

Toolbar.propTypes = {
  className: PropTypes.string,
  filtre: PropTypes.any,
  setFiltre: PropTypes.func,
  lancerRecherche: PropTypes.func.isRequired
};

export default Toolbar;
