import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Container,
  LinearProgress,
  makeStyles,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton,
  Button
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import DeleteIcon from '@material-ui/icons/Delete';
import Page from 'src/components/Page';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import { isUserIsAdmin } from '../auth/Auth.selectors';
import { getAssociations } from './Association.selectors';
import {
  listerAssociations,
  supprimerAssociation
} from './Association.actions';
import AddAssociation from './AddAssociation';
import ConfirmDialog from 'src/components/Dialog/ConfirmDialog.component';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AssociationView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const admin = useSelector(state => isUserIsAdmin(state));
  const associations = useSelector(state => getAssociations(state));
  const fetching = useSelector(state =>
    getIsFetching(state, 'association:lister')
  );

  const [open, setOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);
  const [association, setAssociation] = useState(null);

  useEffect(() => {
    if (!associations) {
      dispatch(listerAssociations());
    }
  }, [associations, dispatch]);

  if (!admin) {
    return null;
  }

  return (
    <Page className={classes.root} title="Gestion des associations">
      <Container maxWidth={false}>
        <div
          style={{
            textAlign: 'center',
            width: '100%',
            marginTop: 10,
            marginBottom: 10
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => {
              setAssociation({ nom: '', code: '' });
              setOpen(true);
            }}
          >
            Ajouter association
          </Button>
        </div>

        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}

        <Card>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Code</TableCell>
                    <TableCell>Nom</TableCell>
                    <TableCell>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {associations &&
                    associations.map(ass => (
                      <TableRow hover key={ass.code}>
                        <TableCell>{ass.code}</TableCell>
                        <TableCell>{ass.nom}</TableCell>
                        <TableCell>
                          <IconButton
                            onClick={() => {
                              setAssociation(ass);
                              setOpen(true);
                            }}
                          >
                            <EditIcon />
                          </IconButton>
                          <IconButton
                            onClick={() => {
                              setAssociation(ass);
                              setOpenDelete(true);
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
        </Card>

        <AddAssociation
          open={open}
          association={association}
          handleClose={() => setOpen(false)}
        />

        <ConfirmDialog
          open={openDelete}
          handleClose={() => setOpenDelete(false)}
          handleValidate={() => {
            dispatch(supprimerAssociation(association.code));
            setOpenDelete(false);
          }}
          title="Êtes-vous sûr de vouloir supprimer l'association ?"
        />
      </Container>
    </Page>
  );
};

export default AssociationView;
