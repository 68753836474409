import React, { useEffect } from 'react';
import { Link as RouterLink, useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
// import moment from 'moment';
import {
  Avatar,
  Box,
  IconButton,
  Divider,
  Drawer,
  Hidden,
  List,
  Typography,
  makeStyles
} from '@material-ui/core';
import {
  BarChart as BarChartIcon,
  Users as UsersIcon,
  User as UserIcon
} from 'react-feather';
import InputIcon from '@material-ui/icons/Input';
import NavItem from './NavItem';
import FingerprintIcon from '@material-ui/icons/Fingerprint';
import HowToVoteIcon from '@material-ui/icons/HowToVote';
import InsertChartIcon from '@material-ui/icons/InsertChart';
import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
import PeopleAltIcon from '@material-ui/icons/PeopleAlt';
import SmsIcon from '@material-ui/icons/Sms';
import DraftsIcon from '@material-ui/icons/Drafts';
import GavelIcon from '@material-ui/icons/Gavel';
// import { getConfig } from 'src/setup/platform';

// const dateOuverture = moment(getConfig('dateDebutResulat'), 'YYYY-MM-DD HH:mm');

const items = [
  {
    href: '/app/dashboard',
    icon: BarChartIcon,
    title: 'Tableau de bord'
  },
  {
    href: '/app/vote',
    icon: DraftsIcon,
    title: 'Vote'
  },
  {
    href: '/app/resultats',
    icon: GavelIcon,
    title: 'Résultats',
    timer: true
  },
  {
    href: '/app/electeurs',
    icon: UsersIcon,
    title: 'Base de données électeurs'
  },
  {
    href: '/app/avalider',
    icon: CheckCircleOutlineIcon,
    title: 'Saisies à valider',
    dep: true
  },
  {
    href: '/app/lieuxvote',
    icon: HowToVoteIcon,
    title: 'Lieux de vote',
    adminOnly: true
  },
  {
    href: '/app/association',
    icon: PeopleAltIcon,
    title: 'Associations',
    adminOnly: true
  },
  {
    href: '/app/rapport',
    icon: InsertChartIcon,
    title: 'Historique Resultats',
    dep: true
  },
  {
    href: '/app/identifiants',
    icon: FingerprintIcon,
    title: 'Utilisateurs',
    adminOnly: true
  },
  {
    href: '/app/statsms',
    icon: SmsIcon,
    title: 'Stats SMS',
    adminOnly: true
  }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

const NavBar = ({
  user,
  isUserIsAdmin,
  isUserIsDepartemental,
  logoutUser,
  onMobileClose,
  openMobile
}) => {
  const classes = useStyles();
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  const content = (
    <Box height="100%" display="flex" flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2}>
        <Avatar
          className={classes.avatar}
          component={RouterLink}
          to="/app/dashboard"
        >
          <UserIcon />
        </Avatar>
        <Typography
          className={classes.name}
          style={{ textAlign: 'center' }}
          color="textPrimary"
          variant="subtitle2"
        >
          {user.nomAffichable}
        </Typography>
        <Typography color="textSecondary" variant="body2">
          {user.roles[0].substring(5)}
        </Typography>
      </Box>
      <Divider />
      <Box p={2}>
        <List>
          {items.map(item => (
            // <div key={item.title}>
            //   {((!item.adminOnly && !item.dep && !item.timer) ||
            //     (item.dep && (isUserIsAdmin || isUserIsDepartemental)) ||
            //     (item.adminOnly && isUserIsAdmin) ||
            //     (item.timer && moment().isSameOrAfter(dateOuverture))) && (
            //     <NavItem
            //       href={item.href}
            //       key={item.title}
            //       title={item.title}
            //       icon={item.icon}
            //     />
            //   )}
            // </div>
            <div key={item.title}>
              {((!item.adminOnly && !item.dep) ||
                (item.dep && (isUserIsAdmin || isUserIsDepartemental)) ||
                (item.adminOnly && isUserIsAdmin)) && (
                <NavItem
                  href={item.href}
                  key={item.title}
                  title={item.title}
                  icon={item.icon}
                />
              )}
            </div>
          ))}
        </List>
      </Box>
      <Hidden lgUp>
        <Box p={2}>
          <IconButton
            color="secondary"
            onClick={() => {
              logoutUser();
              navigate('/login', { replace: true });
            }}
          >
            <InputIcon />
            <span style={{ marginLeft: 10, fontSize: 15 }}>Déconnexion</span>
          </IconButton>
        </Box>
      </Hidden>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

NavBar.defaultProps = {
  onMobileClose: () => {},
  openMobile: false
};

export default NavBar;
