import {
  LOGIN_USER,
  LOGOUT_USER,
  LIRE_IDENTIFIANTS,
  SAVE_IDENTIFIANTS,
  ENREGISTRER_LIEUX_VOTE,
  LISTER_LIEUX_VOTE,
  LISTER_BUREAUX_VOTE,
  LISTER_CEC,
  LISTER_DEPARTEMENT_POLITIQUE
} from './Auth.constants';

const emptyState = {
  identifiant: null,
  nomAffichable: null,
  roles: null,
  state: null,
  token: null,
  refreshToken: null,
  identifiantsGeneres: null
};

let initialState = emptyState;
try {
  const cache = JSON.parse(localStorage.getItem('auth'));
  if (cache) {
    initialState = cache;
  }
} catch (error) {}

const AuthReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT_USER:
      localStorage.removeItem('auth');

      return {
        ...state,
        ...emptyState
      };

    case LOGIN_USER:
      const auth = {
        identifiant: action.identifiant,
        roles: action.roles,
        nomAffichable: action.nomAffichable,
        token: action.token,
        refreshToken: action.refreshToken,
        dateExpirationJeton: action.dateExpirationJeton,
        dirty: false
      };
      localStorage.setItem('auth', JSON.stringify(auth));

      return {
        ...state,
        ...auth
      };
    case LIRE_IDENTIFIANTS:
    case SAVE_IDENTIFIANTS:
      return {
        ...state,
        identifiantsGeneres: action.data
      };
    case ENREGISTRER_LIEUX_VOTE:
    case LISTER_LIEUX_VOTE:
      return {
        ...state,
        lv: action.data
      };
    case LISTER_BUREAUX_VOTE:
      return {
        ...state,
        bv: action.data
      };
    case LISTER_CEC:
      return {
        ...state,
        cec: action.data
      };
    case LISTER_DEPARTEMENT_POLITIQUE:
      return {
        ...state,
        dp: action.data
      };
    default:
      return state;
  }
};

AuthReducer.hydrate = function() {
  return initialState;
};

export default AuthReducer;
