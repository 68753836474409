import React from 'react';
import { Button } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';

export default class ConfirmDialog extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    handleClose: PropTypes.func.isRequired,
    handleValidate: PropTypes.func.isRequired
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{this.props.title}</DialogTitle>
        <DialogContent>
          {this.props.description && (
            <DialogContentText id="alert-dialog-description">
              {this.props.description}
            </DialogContentText>
          )}
          <div>{this.props.children}</div>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.props.handleClose} color="secondary">
            {this.props.noLabel || 'Non'}
          </Button>
          <Button onClick={this.props.handleValidate} color="primary" autoFocus>
            {this.props.yesLabel || 'Oui'}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}
