import { connect } from 'react-redux';
import LoginView from './LoginView.component';
import { loginUser } from './Auth.actions';
import { getUser, isTokenValid } from './Auth.selectors';

function mapStateToProps(state) {
  return {
    user: getUser(state),
    tokenValid: isTokenValid(state)
  };
}

const mapDispatchToProps = {
  loginUser
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginView);
