import {
  UPDATE_DASHBOARD_DATAS,
  UPDATE_RESULTAT,
  LIRE_HISTORIQUE_SMS
} from './Dashboard.constants';
import { LOGOUT_USER } from '../auth/Auth.constants';

const initialState = {};

const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_DASHBOARD_DATAS:
      return {
        ...state,
        data: action.data
      };
    case LIRE_HISTORIQUE_SMS:
      return {
        ...state,
        sms: action.data
      };
    case UPDATE_RESULTAT:
      return {
        ...state,
        resultat: action.data
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };

    default:
      return state;
  }
};

DashboardReducer.hydrate = function() {
  return initialState;
};

export default DashboardReducer;
