import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Container,
  Grid,
  makeStyles,
  Divider,
  colors,
  LinearProgress,
  TextField,
  Card,
  CardContent,
  Typography
} from '@material-ui/core';
import Page from 'src/components/Page';
import PopElectorale from './PopElectorale';
import TotalLV from './TotalLV';
import TotalVotants from './TotalVotants';
import TotalElecteursSaisis from './TotalElecteursSaisis';
import StatRepresentant from './StatRepresentant';
import {
  getCec,
  getDepartementPolitique,
  getLieuxVote,
  isUserIsDepartemental
} from 'src/views/auth/Auth.selectors';
import {
  listerCec,
  listerDepartementPolitique,
  listerLieuxVote
} from 'src/views/auth/Auth.actions';
import { isUserIsAdmin } from 'src/views/auth/Auth.selectors';
import { getDashboardData } from '../Dashboard.selectors';
import { requestDashboardData } from '../Dashboard.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import TranchesAges from './TranchesAges';
import StatTopValues from './StatTopValues';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  },
  partisant: {
    fontSize: 20,
    fontWeight: 'bold',
    marginBottom: 10,
    color: colors.cyan[800]
  }
}));

const Dashboard = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const deps = useSelector(state => getDepartementPolitique(state));
  const cecs = useSelector(state => getCec(state));

  const dashboard = useSelector(state => getDashboardData(state));
  const fetching = useSelector(state => getIsFetching(state, 'dashboard:lire'));

  const userIsAdmin = useSelector(state => isUserIsAdmin(state));
  const userIsDepartemental = useSelector(state =>
    isUserIsDepartemental(state)
  );

  const [cec, setCec] = useState('NULL');
  const [dp, setDp] = useState('NULL');
  const [lv, setLv] = useState('NULL');
  const [listeLv, setListeLv] = useState([]);

  useEffect(() => {
    if (!dashboard) {
      dispatch(requestDashboardData(cec, dp, lv));
    }
  }, [dashboard, dispatch, cec, dp, lv]);

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (!cecs) {
      dispatch(listerCec());
    }
  }, [cecs, dispatch]);

  useEffect(() => {
    if (!deps) {
      dispatch(listerDepartementPolitique());
    }
  }, [deps, dispatch]);

  const computeListeLvDp = val => {
    return lieuxVotes.filter(
      lieuVote =>
        lieuVote.departementPolitique &&
        lieuVote.departementPolitique.code === val
    );
  };

  const computeListeLvCec = val => {
    return lieuxVotes.filter(
      lieuVote => lieuVote.cec && lieuVote.cec.code === val
    );
  };

  const handleChangeCec = event => {
    setCec(event.target.value);
    setDp('NULL');
    setLv('NULL');
    setListeLv(computeListeLvCec(event.target.value));
    dispatch(requestDashboardData(event.target.value, 'NULL', 'NULL'));
  };

  const handleChangeDp = event => {
    setDp(event.target.value);
    setCec('NULL');
    setLv('NULL');
    setListeLv(computeListeLvDp(event.target.value));
    dispatch(requestDashboardData('NULL', event.target.value, 'NULL'));
  };

  const handleChangeLv = event => {
    setLv(event.target.value);
    dispatch(requestDashboardData(cec, dp, event.target.value));
  };

  return (
    <Page className={classes.root} title="Tableau de bord">
      <Container maxWidth={false}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={6} sm={4}>
                <TextField
                  margin="normal"
                  label="CEL"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={cec}
                  onChange={handleChangeCec}
                >
                  <option value="NULL" />
                  {cecs &&
                    cecs.map(option => (
                      <option key={option.code} value={option.code}>
                        {option.nom}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={6} sm={4}>
                <TextField
                  margin="normal"
                  label="Département"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={dp}
                  onChange={handleChangeDp}
                >
                  <option value="NULL" />
                  {deps &&
                    deps.map(option => (
                      <option key={option.code} value={option.code}>
                        {option.nom}
                      </option>
                    ))}
                </TextField>
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  margin="normal"
                  label="Lieu de vote"
                  select
                  fullWidth
                  variant="outlined"
                  SelectProps={{ native: true }}
                  value={lv}
                  onChange={handleChangeLv}
                >
                  <option value="NULL" />
                  {listeLv.map(option => (
                    <option key={option.code} value={option.code}>
                      {option.nom}
                    </option>
                  ))}
                </TextField>
              </Grid>
            </Grid>
          </CardContent>
        </Card>

        <Divider style={{ marginTop: 20, marginBottom: 20 }} />
        {fetching && (
          <Grid item xs={12}>
            <LinearProgress
              classes={{
                colorPrimary: 'primary'
              }}
            />
          </Grid>
        )}

        <Grid container spacing={3}>
          <Grid item xs={12}>
            <PopElectorale
              nbTotalElecteur={dashboard ? dashboard.nbTotalElecteur : 0}
            />
          </Grid>
          {(userIsDepartemental || userIsAdmin) && (
            <>
              <Grid item sm={6} xs={12}>
                <PopElectorale
                  nbTotalElecteur={
                    dashboard ? dashboard.nbTotalContactAverifier : 0
                  }
                  rhdp={true}
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TotalVotants
                  value={dashboard ? dashboard.nbTotalContact : 0}
                  titre="CONTACTS"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TotalVotants
                  value={dashboard ? dashboard.nbTotalPartisant : 0}
                  titre="PARTISANTS RHDP"
                />
              </Grid>

              <Grid item sm={6} xs={12}>
                <TotalVotants
                  value={dashboard ? dashboard.nbTotalSymphatisant : 0}
                  titre="SYMPATHISANTS"
                />
              </Grid>
            </>
          )}
          <Grid item sm={6} xs={12}>
            <TotalLV
              nbLieuVote={
                dashboard && dashboard.nbLieuVote ? dashboard.nbLieuVote : 0
              }
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TotalElecteursSaisis
              value={dashboard ? dashboard.nbElecteursEnregistres : 0}
            />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Nombre d'hommes
                </Typography>
                <Typography variant="h3">
                  {dashboard ? dashboard.nbHommes : 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Nombre de femmes
                </Typography>
                <Typography variant="h3">
                  {dashboard ? dashboard.nbFemmes : 0}
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Typography gutterBottom variant="h6">
                  Moyenne d'âge
                </Typography>
                <Typography variant="h3">
                  {dashboard ? dashboard.moyenneAge : 0} ans
                </Typography>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          <Grid item xs={12}>
            <TranchesAges values={dashboard ? dashboard.tranchesAges : []} />
          </Grid>

          <Grid item xs={12}>
            <Divider />
          </Grid>

          {userIsAdmin && (
            <>
              <Grid item xs={12}>
                <StatTopValues
                  titre="Top 50 des patronymes"
                  datas={dashboard ? dashboard.top50Patronymes : []}
                />
              </Grid>
              <Grid item xs={12}>
                <Divider />
              </Grid>
              <Grid item xs={12}>
                <StatTopValues
                  titre="Top 50 des professions"
                  datas={dashboard ? dashboard.top50Professions : []}
                />
              </Grid>

              <Grid item xs={12}>
                <Divider />
              </Grid>

              <Grid item xs={12}>
                <StatRepresentant
                  datas={dashboard ? dashboard.statRepresentant : []}
                />
              </Grid>
            </>
          )}
        </Grid>
      </Container>
    </Page>
  );
};

export default Dashboard;
