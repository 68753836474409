import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  colors,
  Container,
  makeStyles,
  Grid,
  Button,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import Resultat from './Resultat';
import SyncIcon from '@material-ui/icons/Sync';
import ResultatLv from './ResultatLv';
import ResultatGeneral from './ResultatGeneral';
import { getResultatData } from '../Resultat.selectors';
import {
  isUserIsAdmin,
  isUserIsDepartemental,
  isUserIsRepresentant
} from 'src/views/auth/Auth.selectors';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import {
  listerResultats,
  listerResultatsAdmin,
  listerResultatsSuperviseur,
  saisirResultats
} from '../Resultat.actions';
import SaisieResultatPopup from './SaisieResultatPopup';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const ResultatView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resultats = useSelector(state => getResultatData(state));
  const isRepresentant = useSelector(state => isUserIsRepresentant(state));
  const isSuperviseur = useSelector(state => isUserIsDepartemental(state));
  const isAdmin = useSelector(state => isUserIsAdmin(state));
  const fetching = useSelector(state => getIsFetching(state, 'resultat:lire'));
  const fetchingAdmin = useSelector(state =>
    getIsFetching(state, 'resultat:adminlire')
  );
  const fetchingSuperviseur = useSelector(state =>
    getIsFetching(state, 'resultat:superviseurlire')
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!resultats && isRepresentant) {
      dispatch(listerResultats());
    }
  }, [resultats, isRepresentant, dispatch]);

  useEffect(() => {
    if (!resultats && isSuperviseur) {
      dispatch(listerResultatsSuperviseur());
    }
  }, [resultats, isSuperviseur, dispatch]);

  useEffect(() => {
    if (!resultats && isAdmin) {
      dispatch(listerResultatsAdmin());
    }
  }, [resultats, isAdmin, dispatch]);

  return (
    <Page className={classes.root} title="Résultats">
      <Container maxWidth="lg">
        <Grid container spacing={3}>
          {!isRepresentant && (
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {isAdmin && (
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setOpen(true)}
                  style={{ textAlign: 'center', margin: 10 }}
                >
                  Saisir les résultats
                </Button>
              )}
              <Button
                color="secondary"
                size="large"
                onClick={() => {
                  if (isSuperviseur) {
                    dispatch(listerResultatsSuperviseur());
                  } else {
                    dispatch(listerResultatsAdmin());
                  }
                }}
                variant="contained"
              >
                <SyncIcon style={{ color: colors.common.white }} />
                <span style={{ color: colors.common.white }}>
                  Actualiser les résultats
                </span>
              </Button>
            </Grid>
          )}
          <Grid item xs={12}>
            {(fetchingAdmin || fetching || fetchingSuperviseur) && (
              <LinearProgress
                classes={{
                  colorPrimary: 'primary'
                }}
              />
            )}
          </Grid>
          <Grid item xs={12} style={{ textAlign: 'center' }}>
            {isRepresentant && (
              <Resultat
                resultats={resultats}
                save={payload => dispatch(saisirResultats(payload))}
                showBv={false}
              />
            )}
            {isSuperviseur && <ResultatLv resultatGlobal={resultats} />}
            {isAdmin && (
              <>
                <ResultatGeneral resultatGlobal={resultats} />
                <SaisieResultatPopup
                  resultats={resultats}
                  save={payload => dispatch(saisirResultats(payload))}
                  showBv={!isRepresentant}
                  open={open}
                  handleClose={() => setOpen(false)}
                />
              </>
            )}
          </Grid>
        </Grid>
      </Container>
    </Page>
  );
};

export default ResultatView;
