import {
  ENREGISTRER_ASSOCIATION,
  LISTER_ASSOCIATION,
  SUPPRIMER_ASSOCIATION
} from './Association.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function listerAssociations() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('association:lister', {}, result => ({
        type: LISTER_ASSOCIATION,
        data: result
      }))
    );
  };
}

export function enregistrerAssociation(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'association:enregistrer',
        { body: payload },
        result => ({
          type: ENREGISTRER_ASSOCIATION,
          data: result
        }),
        null,
        "L'association a bien été enregistrée."
      )
    );
  };
}

export function supprimerAssociation(code) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'association:supprimer',
        { url: code },
        result => ({
          type: SUPPRIMER_ASSOCIATION,
          data: result
        }),
        null,
        "L'association a bien été supprimée."
      )
    );
  };
}
