import { LOGOUT_USER } from '../auth/Auth.constants';
import { SAISIR_VOTE, LIRE_ELECTEUR, LIRE_STATS_VOTE } from './Vote.constants';

const initialState = {};

const VoteReducer = (state = initialState, action) => {
  switch (action.type) {
    case SAISIR_VOTE:
    case LIRE_ELECTEUR:
      return {
        ...state,
        data: action.data,
        statsVotes: undefined
      };
    case LIRE_STATS_VOTE:
      return {
        ...state,
        statsVotes: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

VoteReducer.hydrate = function() {
  return initialState;
};

export default VoteReducer;
