import { connect } from 'react-redux';
import NavBar from './NavBar.component';
import { logoutUser } from 'src/views/auth/Auth.actions';
import {
  getUser,
  isUserIsAdmin,
  isUserIsDepartemental
} from 'src/views/auth/Auth.selectors';

function mapStateToProps(state) {
  return {
    user: getUser(state),
    isUserIsAdmin: isUserIsAdmin(state),
    isUserIsDepartemental: isUserIsDepartemental(state)
  };
}

const mapDispatchToProps = {
  logoutUser
};

export default connect(mapStateToProps, mapDispatchToProps)(NavBar);
