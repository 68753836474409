import React from 'react';
import { Button, Divider } from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import PropTypes from 'prop-types';
import SelectElecteurCard from 'src/views/electeur/ElecteurListView/SelectElecteurCard';

export default class ConfirmDialogElecteur extends React.PureComponent {
  static propTypes = {
    open: PropTypes.bool.isRequired,
    electeurs: PropTypes.array,
    handleClose: PropTypes.func.isRequired,
    handleValidate: PropTypes.func.isRequired
  };

  render() {
    return (
      <Dialog
        open={this.props.open}
        onClose={this.props.handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Electeurs existants</DialogTitle>
        <DialogContent>
          <div>
            Nous avons trouvé des élécteurs qui correspondent aux informations
            saisies. Veuillez choisir celui qui correspond à votre saisie.
          </div>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {this.props.electeurs &&
            this.props.electeurs.map(electeur => (
              <SelectElecteurCard
                key={electeur.id}
                electeur={electeur}
                select={this.props.handleValidate}
              />
            ))}

          <Divider style={{ marginTop: 20, marginBottom: 20 }} />

          <div style={{ textAlign: 'center' }}>
            <Button
              onClick={this.props.handleClose}
              style={{ textAlign: 'center' }}
            >
              Pas dans la liste
            </Button>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
