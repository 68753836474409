import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Container,
  makeStyles,
  LinearProgress,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
  MenuItem
} from '@material-ui/core';
import Page from 'src/components/Page';
import {
  enregistrerLieuxVote,
  listerCec,
  listerDepartementPolitique,
  listerLieuxVote
} from '../auth/Auth.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import {
  getCec,
  getDepartementPolitique,
  getLieuxVote
} from '../auth/Auth.selectors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const LieuVoteView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const dp = useSelector(state => getDepartementPolitique(state));
  const cec = useSelector(state => getCec(state));

  const fetching = useSelector(state =>
    getIsFetching(state, 'lieuxVote:lister')
  );

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (!cec) {
      dispatch(listerCec());
    }
  }, [cec, dispatch]);

  useEffect(() => {
    if (!dp) {
      dispatch(listerDepartementPolitique());
    }
  }, [dp, dispatch]);

  return (
    <Page className={classes.root} title="Lieux de vote">
      <Container maxWidth={false}>
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}

        {lieuxVotes && lieuxVotes.length > 0 && (
          <Card>
            <PerfectScrollbar>
              <Box minWidth={800}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>Code</TableCell>
                      <TableCell>Nom</TableCell>
                      <TableCell style={{ width: '30%' }}>CEL</TableCell>
                      <TableCell style={{ width: '30%' }}>
                        Département
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {lieuxVotes.map(lv => (
                      <TableRow hover key={lv.code}>
                        <TableCell>{lv.code}</TableCell>
                        <TableCell>{lv.nom}</TableCell>
                        <TableCell style={{ width: '30%' }}>
                          <TextField
                            select
                            fullWidth
                            label="CEL"
                            onChange={event =>
                              dispatch(
                                enregistrerLieuxVote({
                                  ...lv,
                                  cec: { code: event.target.value }
                                })
                              )
                            }
                            value={lv.cec ? lv.cec.code : ''}
                            variant="outlined"
                          >
                            <MenuItem />
                            {cec &&
                              cec.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.nom}
                                </MenuItem>
                              ))}
                          </TextField>
                        </TableCell>
                        <TableCell style={{ width: '30%' }}>
                          <TextField
                            select
                            fullWidth
                            label="Département"
                            onChange={event =>
                              dispatch(
                                enregistrerLieuxVote({
                                  ...lv,
                                  departementPolitique: {
                                    code: event.target.value
                                  }
                                })
                              )
                            }
                            value={
                              lv.departementPolitique
                                ? lv.departementPolitique.code
                                : ''
                            }
                            variant="outlined"
                          >
                            <MenuItem />
                            {dp &&
                              dp.map(option => (
                                <MenuItem key={option.code} value={option.code}>
                                  {option.nom}
                                </MenuItem>
                              ))}
                          </TextField>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </Box>
            </PerfectScrollbar>
          </Card>
        )}
      </Container>
    </Page>
  );
};

export default LieuVoteView;
