import React from 'react';
import PropTypes from 'prop-types';
import { Card, CardContent, colors, Divider, Grid } from '@material-ui/core';
import ResultatTotal from './ResultatTotal';
import ResultatInfos from './ResultatInfos';
import ResultatLv from './ResultatLv';

const ResultatGeneral = ({ resultatGlobal, className, ...rest }) => {
  if (!resultatGlobal) {
    return null;
  }

  return (
    <>
      <Card style={{ marginBottom: 10 }}>
        <CardContent style={{ backgroundColor: colors.lightBlue[50] }}>
          <ResultatInfos resultat={resultatGlobal.resultatsGlobal} />
          <Grid container spacing={3} style={{ marginBottom: 10 }}>
            {resultatGlobal.resultatsGlobal &&
              resultatGlobal.resultatsGlobal.resultatsCandidat.map(
                (resG, i) => (
                  <Grid key={'resG' + i} item xs={6} sm={4}>
                    <ResultatTotal resultat={resG} />
                  </Grid>
                )
              )}
          </Grid>
        </CardContent>
      </Card>
      <Divider />
      <Grid container style={{ marginTop: 10 }}>
        {resultatGlobal.resultatsLieu &&
          resultatGlobal.resultatsLieu.map((lv, i) => (
            <Grid key={i} item xs={12}>
              <ResultatLv resultatGlobal={lv} />
            </Grid>
          ))}
      </Grid>
    </>
  );
};

ResultatGeneral.propTypes = {
  className: PropTypes.string,
  resultatGlobal: PropTypes.object
};

export default ResultatGeneral;
