import { connect } from 'react-redux';
import ElecteurListView from './ElecteurListView.component';
import { rechercherElecteur } from '../Electeur.actions';
import { getElecteursData } from '../Electeur.selectors';
import { getIsFetching, getIsFetched } from 'src/setup/Api/Api.selectors';
import { getToken, isUserIsAdmin } from 'src/views/auth/Auth.selectors';

function mapStateToProps(state) {
  return {
    electeurs: getElecteursData(state),
    token: getToken(state),
    fetching: getIsFetching(state, 'electeur:rechercher'),
    fetched: getIsFetched(state, 'electeur:rechercher'),
    userIsAdmin: isUserIsAdmin(state)
  };
}

const mapDispatchToProps = {
  rechercherElecteur
};

export default connect(mapStateToProps, mapDispatchToProps)(ElecteurListView);
