import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({ className, identifiants, ...rest }) => {
  const classes = useStyles();

  const [limit] = useState(15);
  const [page, setPage] = useState(0);
  const [min, setMin] = useState(0);
  const [max, setMax] = useState(15);

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
    setMin(newPage * limit);
    setMax((newPage + 1) * limit);
  };

  return (
    <>
      {identifiants && identifiants.length > 0 && (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box minWidth={800}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Lieu de vote</TableCell>
                    <TableCell>Identifiant</TableCell>
                    <TableCell>Mot de passe</TableCell>
                    <TableCell>Nom et Prénoms</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Profil</TableCell>
                    <TableCell>Dernière connexion</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {identifiants.slice(min, max).map(identifiant => (
                    <TableRow hover key={identifiant.identifiant}>
                      <TableCell>{identifiant.lieuxVote}</TableCell>
                      <TableCell>{identifiant.identifiant}</TableCell>
                      <TableCell>{identifiant.password}</TableCell>
                      <TableCell>{`${identifiant.nom} ${identifiant.prenom}`}</TableCell>
                      <TableCell>{identifiant.telephone}</TableCell>
                      <TableCell>{identifiant.profil}</TableCell>
                      <TableCell>
                        {identifiant.dateDernierConnexion
                          ? moment(identifiant.dateDernierConnexion).format(
                              'DD/MM/YYYY à HH:mm'
                            )
                          : ''}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={identifiants ? identifiants.length : 0}
            page={page}
            rowsPerPage={limit}
            rowsPerPageOptions={[15]}
            labelRowsPerPage="Ligne par page"
            onChangePage={handlePageChange}
          />
        </Card>
      )}
      {(!identifiants || identifiants.length === 0) && (
        <Typography component="p">
          Les identifiants préalablement générés ont été masqués pour des
          raisons de sécurité. Vous pouvez, à tous moment, en demander à générer
          d'autres en remplacement des anciens identifiants.
        </Typography>
      )}
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  identifiants: PropTypes.array
};

export default Results;
