import React from 'react';
import clsx from 'clsx';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Box,
  Card,
  CardHeader,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  actions: {
    justifyContent: 'flex-end'
  }
}));

const StatTopValues = ({ className, datas, titre, ...rest }) => {
  const classes = useStyles();

  return (
    <Card className={clsx(classes.root, className)} {...rest}>
      <CardHeader title={titre} />
      <Divider />
      <PerfectScrollbar>
        <Box>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Valeur</TableCell>
                <TableCell>Quantité</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {datas &&
                datas.map((data, index) => (
                  <TableRow hover key={index}>
                    <TableCell>{data.label}</TableCell>
                    <TableCell>{data.value}</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </Box>
      </PerfectScrollbar>
    </Card>
  );
};

StatTopValues.propTypes = {
  className: PropTypes.string,
  titre: PropTypes.string,
  datas: PropTypes.array
};

export default StatTopValues;
