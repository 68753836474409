import { connect } from 'react-redux';
import Component from './Toaster.component';
import { getErrorsMessages } from 'src/setup/Api/Api.selectors';
import { clearApiMessage } from 'src/setup/Api/Api.actions';

const mapStateToProps = state => {
  return {
    toasts: getErrorsMessages(state)
  };
};

export default connect(mapStateToProps, { clearApiMessage })(Component);
