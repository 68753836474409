import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  Button,
  colors
} from '@material-ui/core';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const SelectElecteurCard = ({ select, className, electeur, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={{ marginTop: 5 }}
    >
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography
            align="center"
            style={{ color: colors.orange[800], fontWeight: 'bold' }}
            gutterBottom
            variant="h4"
          >
            {electeur.numero}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              Nom: {electeur.nom}
            </Typography>
            {electeur.nomJeuneFille && (
              <Typography
                align="left"
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                Nom de jeune fille: {electeur.nomJeuneFille}
              </Typography>
            )}
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              Prénoms: {electeur.prenom}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Sexe: {electeur.sexe}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Né(e) le: {moment(electeur.dateNaissance).format('DD/MM/YYYY')} à{' '}
              {electeur.lieuNaissance}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Nom Père: {electeur.nomPere}
            </Typography>

            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Prénom Père: {electeur.prenomPere}
            </Typography>

            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Nom Mère: {electeur.nomMere}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Prénom Mère: {electeur.prenomMere}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Profession: {electeur.profession}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />

      <div style={{ textAlign: 'center' }}>
        <Button
          onClick={() => select(electeur)}
          color="primary"
          style={{ textAlign: 'center' }}
        >
          Choisir
        </Button>
      </div>
    </Card>
  );
};

SelectElecteurCard.propTypes = {
  className: PropTypes.string,
  electeur: PropTypes.object.isRequired,
  select: PropTypes.func.isRequired
};

export default SelectElecteurCard;
