import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { getBureauxVote, getLieuxVote } from 'src/views/auth/Auth.selectors';
import {
  inviterUtilisateur,
  listerBureauxVote,
  listerLieuxVote
} from 'src/views/auth/Auth.actions';

const ConnecterElecteurPopup = ({ open, handleClose, electeur }) => {
  const dispatch = useDispatch();

  const lieuxVote = useSelector(state => getLieuxVote(state));
  const bureauxVote = useSelector(state => getBureauxVote(state));

  useEffect(() => {
    if (!bureauxVote) {
      dispatch(listerBureauxVote());
    }
  }, [bureauxVote, dispatch]);

  useEffect(() => {
    if (!lieuxVote) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVote, dispatch]);

  if (!electeur) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Création du compte d'un électeur
      </DialogTitle>
      <DialogContent>
        <div>
          Création du compte de l'électeur {electeur.nom} {electeur.prenom}.
        </div>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Formik
          initialValues={{
            numeroElecteur: electeur.numero,
            codeBv: '',
            codeLv: '',
            principal: ''
          }}
          validationSchema={Yup.object().shape({
            numeroElecteur: Yup.string().required(
              "Veuillez saisir le numero d'électeur"
            ),
            codeBv: Yup.string().required(
              'Veuillez sélectionner un bureau de vote'
            ),
            codeLv: Yup.string().required(
              'Veuillez sélectionner un bureau de vote'
            ),
            principal: Yup.bool().nullable()
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(inviterUtilisateur({ ...values }));
            handleClose();
            resetForm();
          }}
        >
          {({ handleChange, handleSubmit, values, isValid }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Lieu de vote"
                        name="codeLv"
                        onChange={handleChange}
                        value={values.codeLv}
                        variant="outlined"
                        required
                      >
                        <MenuItem value={null}>
                          --- Sélectionner un LV ---
                        </MenuItem>
                        {lieuxVote &&
                          lieuxVote.map(option => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.nom}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Bureau de vote"
                        name="codeBv"
                        onChange={handleChange}
                        value={values.codeBv}
                        variant="outlined"
                        required
                        disabled={!values.codeLv}
                      >
                        <MenuItem value={null}>
                          --- Sélectionner un BV ---
                        </MenuItem>
                        {bureauxVote &&
                          bureauxVote
                            .filter(bv => bv.lieuVote.code === values.codeLv)
                            .map(option => (
                              <MenuItem key={option.code} value={option.code}>
                                {option.nom}
                              </MenuItem>
                            ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.principal}
                                onChange={handleChange}
                                color="secondary"
                                name="principal"
                              />
                            }
                            label="Est en représentant principal ?"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={handleClose}
                          style={{ marginRight: 20 }}
                        >
                          Annuler
                        </Button>
                        <Button
                          color="primary"
                          disabled={!isValid}
                          variant="contained"
                          type="submit"
                        >
                          Valider
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ConnecterElecteurPopup.propTypes = {
  open: PropTypes.bool,
  electeur: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ConnecterElecteurPopup;
