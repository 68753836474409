import React from 'react';
import PropTypes from 'prop-types';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  label: {
    fontSize: 10
  },
  logo: {
    margin: 'auto'
  },
  itemDiv: {
    textAlign: 'center',
    border: '1px solid lightgray',
    borderRadius: 5
  }
}));

const ResultatCandidat = ({ resultat, resultatCand }) => {
  const classes = useStyles();

  return (
    <div className={classes.itemDiv}>
      <Typography gutterBottom style={{ fontSize: 12 }}>
        {`${resultatCand.candidat} (${resultatCand.partiPolitique})`}
      </Typography>

      {resultat.suffrageExprime > 0 && (
        <Typography variant="h3">
          {Math.round((resultatCand.voix * 100) / resultat.suffrageExprime)} %
        </Typography>
      )}
      <Typography component="p" style={{ fontSize: 9 }}>
        Voix: {resultatCand.voix}
      </Typography>
    </div>
  );
};

ResultatCandidat.propTypes = {
  resultatCand: PropTypes.object,
  resultat: PropTypes.object
};

export default ResultatCandidat;
