import fetch from 'isomorphic-fetch';
import { getToken } from 'src/views/auth/Auth.selectors';
import { requestFailed, requestSent, requestSucceeded } from './Api.actions';
import apiConfig from './api.config';

export function fetchApiAction(
  apiName,
  apiParams,
  actionCreator,
  actionFailedCreator,
  message
) {
  return (dispatch, getState) => {
    const token = getToken(getState());

    dispatch(requestSent(apiName, apiParams));

    return fetchApi(apiName, apiParams, token)
      .then(([response, json]) => {
        if (response.ok) {
          dispatch(requestSucceeded(apiName, json.body, message));
          if (actionCreator) {
            dispatch(actionCreator(json.body));
          }
        } else {
          dispatch(
            requestFailed(apiName, json.codeMessage, json.libelleMessage)
          );
          if (actionFailedCreator) {
            dispatch(actionFailedCreator());
          }
        }
      })
      .catch(error => {
        console.error(error);
        dispatch(requestFailed(apiName));
      });
  };
}

export function fetchApi(apiName, apiParams, token) {
  const api = apiConfig[apiName];
  const options = {
    method: api.method,
    headers: {
      Accept: 'application/json'
    }
  };
  if (api.fileUpload) {
    options.body = apiParams.body;
  } else {
    options.headers['Content-Type'] = 'application/json';
    options.body = JSON.stringify(apiParams.body);
  }

  let url = api.url;
  if (typeof url === 'function') {
    url = url(apiParams.url);
  }

  if (api.secure) {
    options.headers['Authorization'] = `Bearer ${token}`;
  }

  return fetch(url, options).then(response => {
    return Promise.all([response, response.json().catch(() => ({}))]);
  });
}
