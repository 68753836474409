import { LOGOUT_USER } from '../auth/Auth.constants';
import { LISTER_CANDIDATS } from './Candidat.constants';

const initialState = {};

const CandidatReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_CANDIDATS:
      return {
        ...state,
        data: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

CandidatReducer.hydrate = function() {
  return initialState;
};

export default CandidatReducer;
