import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  MenuItem,
  LinearProgress
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { envoyerSms } from '../Electeur.actions';
import { getDepartementPolitique } from 'src/views/auth/Auth.selectors';
import { listerDepartementPolitique } from 'src/views/auth/Auth.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';

const SendSmsPopup = ({ open, handleClose }) => {
  const dispatch = useDispatch();

  const deps = useSelector(state => getDepartementPolitique(state));
  const fetching = useSelector(state => getIsFetching(state, 'sms:send'));

  useEffect(() => {
    if (!deps) {
      dispatch(listerDepartementPolitique());
    }
  }, [deps, dispatch]);

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Envoi de SMS</DialogTitle>
      <DialogContent>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Formik
          initialValues={{
            message: '',
            tous: false,
            musulmans: false,
            chretiens: false,
            departement: '',
            telephone: ''
          }}
          validationSchema={Yup.object().shape({
            message: Yup.string().required(
              "Veuillez saisir le numero de téléphone de l'électeur"
            ),
            tous: Yup.bool(),
            musulmans: Yup.bool(),
            chretiens: Yup.bool(),
            departement: Yup.string(),
            telephone: Yup.string()
          })}
          onSubmit={values => {
            dispatch(
              envoyerSms({
                ...values,
                telephone:
                  values.telephone !== '' ? values.telephone.split(';') : null
              })
            );
          }}
        >
          {({ handleChange, handleSubmit, values, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.tous}
                                onChange={event => {
                                  setFieldValue('tous', event.target.checked);
                                  setFieldValue('musulmans', false);
                                  setFieldValue('chretiens', false);
                                  setFieldValue('departement', '');
                                  setFieldValue('telephone', '');
                                }}
                                color="secondary"
                                name="tous"
                                disabled={
                                  values.musulmans === true ||
                                  values.chretiens === true ||
                                  values.telephone !== '' ||
                                  values.departement !== ''
                                }
                              />
                            }
                            label="Tous les électeurs"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.musulmans}
                                onChange={event => {
                                  setFieldValue(
                                    'musulmans',
                                    event.target.checked
                                  );
                                  setFieldValue('tous', false);
                                  setFieldValue('chretiens', false);
                                  setFieldValue('departement', '');
                                  setFieldValue('telephone', '');
                                }}
                                color="secondary"
                                name="musulmans"
                                disabled={
                                  values.tous === true ||
                                  values.chretiens === true ||
                                  values.telephone !== '' ||
                                  values.departement !== ''
                                }
                              />
                            }
                            label="Musulmans"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.chretiens}
                                onChange={event => {
                                  setFieldValue(
                                    'chretiens',
                                    event.target.checked
                                  );
                                  setFieldValue('tous', false);
                                  setFieldValue('musulmans', false);
                                  setFieldValue('departement', '');
                                  setFieldValue('telephone', '');
                                }}
                                color="secondary"
                                name="chretiens"
                                disabled={
                                  values.tous === true ||
                                  values.musulmans === true ||
                                  values.telephone !== '' ||
                                  values.departement !== ''
                                }
                              />
                            }
                            label="Chrétiens"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Département"
                        name="departement"
                        onChange={event => {
                          setFieldValue('departement', event.target.value);
                          setFieldValue('tous', false);
                          setFieldValue('chretiens', false);
                          setFieldValue('musulmans', false);
                          setFieldValue('telephone', '');
                        }}
                        value={values.departement}
                        variant="outlined"
                        disabled={
                          values.tous === true ||
                          values.chretiens === true ||
                          values.musulmans === true ||
                          values.telephone !== ''
                        }
                      >
                        <MenuItem value="">Tous</MenuItem>
                        {deps &&
                          deps.map(option => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.nom}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Numeros de téléphone"
                        name="telephone"
                        onChange={event => {
                          setFieldValue('telephone', event.target.value);
                          setFieldValue('tous', false);
                          setFieldValue('chretiens', false);
                          setFieldValue('musulmans', false);
                          setFieldValue('departement', '');
                        }}
                        value={values.telephone}
                        variant="outlined"
                        multiline
                        rows={3}
                        helperText={"Séparés par des ';'"}
                        disabled={
                          values.tous === true ||
                          values.chretiens === true ||
                          values.musulmans === true ||
                          values.departement !== ''
                        }
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Message SMS"
                        name="message"
                        onChange={handleChange}
                        required
                        value={values.message}
                        variant="outlined"
                        multiline
                        rows={6}
                        inputProps={{ maxLength: 350 }}
                        helperText={`Nombre de caractère restant: ${350 -
                          values.message.length}`}
                      />
                    </Grid>
                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                    <Grid item xs={12}>
                      {fetching && (
                        <LinearProgress
                          classes={{
                            colorPrimary: 'primary'
                          }}
                        />
                      )}
                    </Grid>
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={handleClose}
                          style={{ marginRight: 20 }}
                        >
                          Annuler
                        </Button>
                        <Button
                          color="primary"
                          disabled={!isValid || fetching}
                          variant="contained"
                          type="submit"
                        >
                          Valider
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

SendSmsPopup.propTypes = {
  open: PropTypes.bool,
  handleClose: PropTypes.func.isRequired
};

export default SendSmsPopup;
