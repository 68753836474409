import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Avatar,
  Card,
  CardContent,
  Grid,
  Typography,
  colors,
  makeStyles
} from '@material-ui/core';
import PeopleIcon from '@material-ui/icons/PeopleOutlined';
import DoneAllIcon from '@material-ui/icons/DoneAll';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  avatar: {
    backgroundColor: colors.red[600],
    height: 56,
    width: 56
  },
  avatarWhite: {
    backgroundColor: colors.common.white,
    height: 56,
    width: 56
  },
  differenceIcon: {
    color: colors.red[900]
  },
  whiteIcon: {
    color: colors.common.white
  },
  redIcon: {
    color: colors.red[400]
  }
}));

const PopElectorale = (props, { className, ...rest }) => {
  const classes = useStyles();

  return (
    <Card
      className={clsx(classes.root, className)}
      {...rest}
      style={props.rhdp ? { backgroundColor: colors.red[400] } : null}
    >
      <CardContent>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Typography
              gutterBottom
              variant="h6"
              style={{ color: props.rhdp ? '#FFF' : colors.blueGrey[600] }}
            >
              {props.rhdp ? 'CONTACTS A VERIFIER' : 'POPULATION ELECTORALE'}
            </Typography>
            <Typography
              variant="h3"
              style={{ color: props.rhdp ? '#FFF' : colors.blueGrey[900] }}
            >
              {props.nbTotalElecteur}
            </Typography>
          </Grid>

          <Grid item>
            <Avatar
              className={props.rhdp ? classes.avatarWhite : classes.avatar}
            >
              {!props.rhdp && <PeopleIcon className={classes.whiteIcon} />}
              {props.rhdp && <DoneAllIcon className={classes.redIcon} />}
            </Avatar>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

PopElectorale.propTypes = {
  className: PropTypes.string
};

export default PopElectorale;
