import { getConfig } from '../platform';

const baseApi = getConfig('apiUrl');

// eslint-disable-next-line
export default {
  'identifiants:lire': {
    url: baseApi + '/utilisateur/identifiants',
    method: 'GET',
    secure: true
  },
  'identifiants:save': {
    url: baseApi + '/utilisateur/creer',
    method: 'POST',
    secure: true
  },
  'auth:login': {
    url: baseApi + '/utilisateur/connexion',
    method: 'POST'
  },
  'auth:refreshToken': {
    url: refreshToken =>
      baseApi + '/utilisateur/refreshToken?refreshToken=' + refreshToken,
    method: 'GET'
  },
  'presence:post': {
    url: baseApi + '/presence',
    method: 'POST',
    secure: true
  },
  'presence:get': {
    url: baseApi + '/presence',
    method: 'GET',
    secure: true
  },
  'presence:lister': {
    url: baseApi + '/presence/lister',
    method: 'GET',
    secure: true
  },
  'electeur:lire': {
    url: numero => baseApi + '/electeur/lire/' + numero,
    method: 'GET',
    secure: true
  },
  'electeur:rechercher': {
    url: baseApi + '/electeur/rechercher',
    method: 'POST',
    secure: true
  },
  'electeur:enregistrer': {
    url: baseApi + '/electeur/enregistrer',
    method: 'POST',
    secure: true
  },
  'electeur:voter': {
    url: baseApi + '/electeur/voter',
    method: 'POST',
    secure: true
  },
  'electeur:controler': {
    url: param =>
      baseApi + '/electeur/controler/' + param.id + '/' + param.rejette,
    method: 'GET',
    secure: true
  },
  'electeur:avalider': {
    url: baseApi + '/electeur/lister/avalider',
    method: 'GET',
    secure: true
  },
  'electeur:professions': {
    url: baseApi + '/electeur/professions',
    method: 'GET',
    secure: true
  },
  'electeur:modifier': {
    url: baseApi + '/electeur/modifier',
    method: 'POST',
    secure: true
  },

  'dashboard:lire': {
    url: param =>
      baseApi + `/dashboard/lire/${param.cec}/${param.dp}/${param.lv}`,
    method: 'GET',
    secure: true
  },
  'dashboard:resultat': {
    url: param =>
      baseApi +
      `/dashboard/resultat/${param.type}/${param.annee}/${param.cec}/${param.dp}/${param.lv}`,
    method: 'GET',
    secure: true
  },
  'candidat:lister': {
    url: baseApi + '/resultats/candidat/lister/',
    method: 'GET',
    secure: true
  },
  'resultat:lire': {
    url: baseApi + '/resultats/lire',
    method: 'GET',
    secure: true
  },
  'resultat:saisir': {
    url: baseApi + '/resultats/saisir',
    method: 'POST',
    secure: true
  },
  'resultat:adminlire': {
    url: baseApi + '/resultats/admin/lire',
    method: 'GET',
    secure: true
  },
  'resultat:superviseurlire': {
    url: baseApi + '/resultats/superviseur/lire',
    method: 'GET',
    secure: true
  },
  'lieuxVote:lister': {
    url: baseApi + '/lieuVote/lister',
    method: 'GET',
    secure: true
  },
  'bureauxVote:lister': {
    url: baseApi + '/lieuVote/bv/lister',
    method: 'GET',
    secure: true
  },

  'cec:lister': {
    url: baseApi + '/lieuVote/cec/lister',
    method: 'GET',
    secure: true
  },
  'dp:lister': {
    url: baseApi + '/lieuVote/dp/lister',
    method: 'GET',
    secure: true
  },
  'lieuxVote:enregistrer': {
    url: baseApi + '/lieuVote/enregistrer',
    method: 'POST',
    secure: true
  },
  'association:enregistrer': {
    url: baseApi + '/association/enregistrer',
    method: 'POST',
    secure: true
  },
  'association:lister': {
    url: baseApi + '/association/lister',
    method: 'GET',
    secure: true
  },
  'association:supprimer': {
    url: code => baseApi + `/association/supprimer/${code}`,
    method: 'DELETE',
    secure: true
  },
  'sms:send': {
    url: baseApi + `/sms`,
    method: 'POST',
    secure: true
  },
  'sms:stat': {
    url: baseApi + `/sms/historique`,
    method: 'GET',
    secure: true
  },
  'utilisateur:inviter': {
    url: baseApi + `/utilisateur/representant/creer`,
    method: 'POST',
    secure: true
  },
  'electeur:vote': {
    url: param => baseApi + `/resultats/voter/${param.numero}/${param.aVote}`,
    method: 'GET',
    secure: true
  },
  'electeur:lireStatVote': {
    url: baseApi + `/resultats/statsvotes`,
    method: 'GET',
    secure: true
  }
};
