import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  LIRE_FICHE_PRESENCE,
  ENREGISTRER_FICHE_PRESENCE,
  LISTER_PRESENCES
} from './Presence.constants';

const initialState = {};

const PresenceReducer = (state = initialState, action) => {
  switch (action.type) {
    case LIRE_FICHE_PRESENCE:
    case ENREGISTRER_FICHE_PRESENCE:
      return {
        ...state,
        data: action.data
      };
    case LISTER_PRESENCES:
      return {
        ...state,
        list: action.data
      };
    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

PresenceReducer.hydrate = function() {
  return initialState;
};

export default PresenceReducer;
