export const LOGIN_USER = 'auth/LOGIN_USER';
export const LIRE_IDENTIFIANTS = 'auth/LIRE_IDENTIFIANTS';
export const SAVE_IDENTIFIANTS = 'auth/SAVE_IDENTIFIANTS';
export const LOGOUT_USER = 'auth/LOGOUT_USER';
export const LISTER_LIEUX_VOTE = 'lv/LISTER_LIEUX_VOTE';
export const LISTER_BUREAUX_VOTE = 'lv/LISTER_BUREAUX_VOTE';
export const ENREGISTRER_LIEUX_VOTE = 'lv/ENREGISTRER_LIEUX_VOTE';
export const INVITER_UTILISATEUR = 'auth/INVITER_UTILISATEUR';
export const LISTER_CEC = 'lv/LISTER_CEC';
export const LISTER_DEPARTEMENT_POLITIQUE = 'lv/LISTER_DEPARTEMENT_POLITIQUE';
