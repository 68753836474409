import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  MenuItem,
  Container,
  makeStyles,
  LinearProgress
} from '@material-ui/core';
import Page from 'src/components/Page';
import { getLieuxVote } from 'src/views/auth/Auth.selectors';
import { listerLieuxVote } from 'src/views/auth/Auth.actions';
import {
  enregistrerElecteur,
  resetExistingElecteur
} from '../Electeur.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import { getElecteurExistant } from '../Electeur.selectors';
import ConfirmDialogElecteur from 'src/components/Dialog/ConfirmDialogElecteur.component';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const AddElecteur = () => {
  const dispatch = useDispatch();
  const classes = useStyles();

  const lieuxVotes = useSelector(state => getLieuxVote(state));
  const electeurExistant = useSelector(state => getElecteurExistant(state));

  const fetching = useSelector(state =>
    getIsFetching(state, 'electeur:enregistrer')
  );

  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (!lieuxVotes) {
      dispatch(listerLieuxVote());
    }
  }, [lieuxVotes, dispatch]);

  useEffect(() => {
    if (electeurExistant && electeurExistant.length > 0) {
      setOpen(true);
    }
  }, [electeurExistant, dispatch]);

  return (
    <Page className={classes.root} title="Electeurs">
      <Container maxWidth={false}>
        {fetching && (
          <LinearProgress
            classes={{
              colorPrimary: 'primary'
            }}
          />
        )}
        <Formik
          initialValues={{
            numero: '',
            nom: '',
            nomJeuneFille: '',
            prenom: '',
            contact: '',
            sexe: '',
            lieuNaissance: '',
            dateNaissance: '1960-01-01',
            nomPere: '',
            prenomPere: '',
            nomMere: '',
            prenomMere: '',
            profession: '',
            statut: '',
            lieuVoteDTO: { code: '' }
          }}
          validationSchema={Yup.object().shape({
            numero: Yup.string().nullable(),
            nom: Yup.string().required('Veuillez saisir le nom'),
            nomJeuneFille: Yup.string().nullable(),
            prenom: Yup.string().required('Veuillez saisir le prénom'),
            contact: Yup.string().nullable(),
            sexe: Yup.string().required('Veuillez sélectionner le sexe'),
            lieuNaissance: Yup.string().nullable(),
            dateNaissance: Yup.string().required(
              'Veuillez saisir la date de naissance'
            ),
            nomPere: Yup.string().nullable(),
            prenomPere: Yup.string().nullable(),
            nomMere: Yup.string().nullable(),
            prenomMere: Yup.string().nullable(),
            profession: Yup.string().nullable(),
            statut: Yup.string().required('Veuillez saisir le statut'),
            lieuVoteDTO: Yup.object().shape({
              code: Yup.string().nullable()
            })
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(enregistrerElecteur({ ...values }));
            resetForm();
          }}
        >
          {({ handleChange, handleSubmit, values, isValid, setFieldValue }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardHeader
                  subheader="Veuillez saisir les informations du nouvel électeur"
                  title="Ajout d'un nouvel électeur"
                />
                <Divider />
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="Nom"
                        name="nom"
                        onChange={handleChange}
                        required
                        value={values.nom}
                        variant="outlined"
                        disabled={values.id}
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="Prénoms"
                        name="prenom"
                        onChange={handleChange}
                        required
                        value={values.prenom}
                        variant="outlined"
                        disabled={values.id}
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Nom de jeune fille"
                        name="nomJeuneFille"
                        onChange={handleChange}
                        value={values.nomJeuneFille}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <TextField
                        select
                        fullWidth
                        label="Sexe"
                        name="sexe"
                        onChange={handleChange}
                        value={values.sexe}
                        variant="outlined"
                        disabled={values.id}
                        required
                      >
                        <MenuItem value="M">Masculin</MenuItem>
                        <MenuItem value="F">Féminin</MenuItem>
                      </TextField>
                    </Grid>

                    <Grid item md={4} xs={6}>
                      <TextField
                        label="Date de naissance"
                        name="dateNaissance"
                        type="date"
                        fullWidth
                        required
                        onChange={handleChange}
                        value={values.dateNaissance}
                        disabled={values.id}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Lieu de naissance"
                        name="lieuNaissance"
                        onChange={handleChange}
                        value={values.lieuNaissance}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="Téléphone"
                        name="contact"
                        required
                        onChange={handleChange}
                        value={values.contact}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={12}>
                      <TextField
                        fullWidth
                        label="Numéro d'électeur"
                        name="numero"
                        onChange={handleChange}
                        value={values.numero}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={4} xs={6}>
                      <TextField
                        fullWidth
                        label="Profession"
                        name="profession"
                        onChange={handleChange}
                        value={values.profession}
                        variant="outlined"
                      />
                    </Grid>
                  </Grid>

                  <Divider style={{ marginBottom: 15, marginTop: 15 }} />

                  <Grid container spacing={6} wrap="wrap">
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nom du père"
                        name="nomPere"
                        onChange={handleChange}
                        value={values.nomPere}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prénoms du père"
                        name="prenomPere"
                        onChange={handleChange}
                        value={values.prenomPere}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Nom de la mère"
                        name="nomMere"
                        onChange={handleChange}
                        value={values.nomMere}
                        variant="outlined"
                      />
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        fullWidth
                        label="Prénoms de la mère"
                        name="prenomMere"
                        onChange={handleChange}
                        value={values.prenomMere}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item md={6} xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Lieu de vote"
                        name="lieuVoteDTO.code"
                        onChange={handleChange}
                        value={
                          values.lieuVoteDTO ? values.lieuVoteDTO.code : ''
                        }
                        variant="outlined"
                      >
                        <MenuItem />
                        {lieuxVotes &&
                          lieuxVotes.map(option => (
                            <MenuItem key={option.code} value={option.code}>
                              {`[${option.numero}] ${option.nom}`}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>
                    <Grid item md={6} xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Statut"
                        name="statut"
                        onChange={handleChange}
                        value={values.statut}
                        required
                        variant="outlined"
                      >
                        <MenuItem value="CONTACT">Contact</MenuItem>
                        <MenuItem value="RHDP">RHDP</MenuItem>
                        <MenuItem value="SYMPHATISANT">Sympathisant</MenuItem>
                      </TextField>
                    </Grid>
                  </Grid>
                </CardContent>
                <Divider />
                <Box display="flex" justifyContent="flex-end" p={2}>
                  <Button
                    color="primary"
                    disabled={!isValid}
                    variant="contained"
                    type="submit"
                  >
                    Enregister
                  </Button>
                </Box>
              </Card>

              <ConfirmDialogElecteur
                open={open}
                electeurs={electeurExistant}
                handleClose={() => {
                  setOpen(false);
                  dispatch(resetExistingElecteur());
                }}
                handleValidate={selected => {
                  setOpen(false);
                  dispatch(resetExistingElecteur());
                  setFieldValue('id', selected.id);
                  setFieldValue('nom', selected.nom);
                  setFieldValue('prenom', selected.prenom);
                  setFieldValue(
                    'nomJeuneFille',
                    selected.nomJeuneFille ? selected.nomJeuneFille : ''
                  );
                  setFieldValue('sexe', selected.sexe ? selected.sexe : '');
                  setFieldValue(
                    'dateNaissance',
                    selected.dateNaissance
                      ? moment(selected.dateNaissance).format('YYYY-MM-DD')
                      : ''
                  );
                  setFieldValue(
                    'lieuNaissance',
                    selected.lieuNaissance ? selected.lieuNaissance : ''
                  );
                  setFieldValue(
                    'contact',
                    selected.contact ? selected.contact : ''
                  );
                  setFieldValue(
                    'numero',
                    selected.numero ? selected.numero : ''
                  );
                  setFieldValue(
                    'profession',
                    selected.profession ? selected.profession : ''
                  );
                  setFieldValue(
                    'nomPere',
                    selected.nomPere ? selected.nomPere : ''
                  );
                  setFieldValue(
                    'prenomPere',
                    selected.prenomPere ? selected.prenomPere : ''
                  );
                  setFieldValue(
                    'nomMere',
                    selected.nomMere ? selected.nomMere : ''
                  );
                  setFieldValue(
                    'prenomMere',
                    selected.prenomMere ? selected.prenomMere : ''
                  );
                  setFieldValue(
                    'lieuVoteDTO.code',
                    selected.lieuVoteDTO ? selected.lieuVoteDTO.code : ''
                  );
                  setFieldValue(
                    'statut',
                    selected.statut ? selected.statut : ''
                  );
                }}
              />
            </form>
          )}
        </Formik>
      </Container>
    </Page>
  );
};

AddElecteur.propTypes = {
  className: PropTypes.string,
  lieuxVotes: PropTypes.array
};

export default AddElecteur;
