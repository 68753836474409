import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  MenuItem,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch
} from '@material-ui/core';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { modifierElecteur } from '../Electeur.actions';
import { getAssociations } from 'src/views/association/Association.selectors';
import { listerAssociations } from 'src/views/association/Association.actions';
import { isUserIsAdmin } from 'src/views/auth/Auth.selectors';

const ModifierElecteurPopup = ({ open, handleClose, electeur }) => {
  const dispatch = useDispatch();

  const associations = useSelector(state => getAssociations(state));
  const userIsAdmin = useSelector(state => isUserIsAdmin(state));

  useEffect(() => {
    if (!associations) {
      dispatch(listerAssociations());
    }
  }, [associations, dispatch]);

  if (!electeur) {
    return null;
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        Modification d'un électeur
      </DialogTitle>
      <DialogContent>
        <div>
          Modification de l'électeur {electeur.nom} {electeur.prenom}.
        </div>
        <Divider style={{ marginBottom: 15, marginTop: 15 }} />
        <Formik
          initialValues={{
            numero: electeur.numero,
            contact: electeur.contact,
            codeAssociation: electeur.codeAssociation,
            contentieux: electeur.contentieux2023,
            motifContentieux: electeur.motifContentieux,
            statut: electeur.statut
          }}
          validationSchema={Yup.object().shape({
            numero: Yup.string().required(
              "Veuillez saisir le numero d'électeur"
            ),
            contact: Yup.string().required(
              "Veuillez saisir le numero de téléphone de l'électeur"
            ),
            motifContentieux: Yup.string().nullable(),
            contentieux: Yup.bool().nullable(),
            codeAssociation: Yup.string().nullable(),
            statut: Yup.string().nullable()
          })}
          onSubmit={(values, { resetForm }) => {
            dispatch(modifierElecteur({ ...values }));
            handleClose();
            resetForm();
          }}
        >
          {({ handleChange, handleSubmit, values, isValid }) => (
            <form onSubmit={handleSubmit}>
              <Card>
                <CardContent>
                  <Grid container spacing={3} wrap="wrap">
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        label="Numéro de téléphone"
                        name="contact"
                        onChange={handleChange}
                        required
                        value={values.contact}
                        variant="outlined"
                        type="text"
                        inputProps={{ maxLength: 10 }}
                        helperText="Limité à 10 chiffres sans espaces ni caractères spéciaux"
                        disabled={!userIsAdmin && electeur.contact}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Association"
                        name="codeAssociation"
                        onChange={handleChange}
                        value={values.codeAssociation}
                        variant="outlined"
                        disabled={!userIsAdmin && electeur.codeAssociation}
                      >
                        <MenuItem value={null}>
                          --- Sans associations ---
                        </MenuItem>
                        {associations &&
                          associations.map(option => (
                            <MenuItem key={option.code} value={option.code}>
                              {option.nom}
                            </MenuItem>
                          ))}
                      </TextField>
                    </Grid>

                    <Grid item xs={12}>
                      <FormControl component="fieldset">
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Switch
                                checked={values.contentieux}
                                onChange={handleChange}
                                color="secondary"
                                name="contentieux"
                              />
                            }
                            label="Est en contentieux ?"
                          />
                        </FormGroup>
                      </FormControl>
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        multiline
                        rows={4}
                        label="Motif du contentieux"
                        name="motifContentieux"
                        onChange={handleChange}
                        required={values.contentieux === true}
                        value={values.motifContentieux}
                        variant="outlined"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <TextField
                        select
                        fullWidth
                        label="Statut"
                        name="statut"
                        onChange={handleChange}
                        value={values.statut}
                        variant="outlined"
                      >
                        <MenuItem value="CONTACT">Contact</MenuItem>
                        <MenuItem value="RHDP">RHDP</MenuItem>
                        <MenuItem value="SYMPHATISANT">Sympathisant</MenuItem>
                      </TextField>
                    </Grid>

                    <Divider style={{ marginBottom: 15, marginTop: 15 }} />
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="flex-end" p={2}>
                        <Button
                          color="default"
                          variant="contained"
                          onClick={handleClose}
                          style={{ marginRight: 20 }}
                        >
                          Annuler
                        </Button>
                        <Button
                          color="primary"
                          disabled={!isValid}
                          variant="contained"
                          type="submit"
                        >
                          Valider
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </form>
          )}
        </Formik>
      </DialogContent>
    </Dialog>
  );
};

ModifierElecteurPopup.propTypes = {
  open: PropTypes.bool,
  electeur: PropTypes.object.isRequired,
  handleClose: PropTypes.func.isRequired
};

export default ModifierElecteurPopup;
