import { LOGOUT_USER } from '../auth/Auth.constants';
import {
  LISTER_RESULTAT,
  LISTER_RESULTAT_ADMIN,
  SAISIR_RESULTAT_ADMIN,
  LISTER_RESULTAT_SUPERVISEUR
} from './Resultat.constants';

const initialState = {};

const ResultatReducer = (state = initialState, action) => {
  switch (action.type) {
    case LISTER_RESULTAT:
    case SAISIR_RESULTAT_ADMIN:
    case LISTER_RESULTAT_ADMIN:
    case LISTER_RESULTAT_SUPERVISEUR:
      return {
        ...state,
        data: action.data
      };

    case LOGOUT_USER:
      return {
        ...initialState
      };
    default:
      return state;
  }
};

ResultatReducer.hydrate = function() {
  return initialState;
};

export default ResultatReducer;
