import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Box,
  Card,
  CardContent,
  Divider,
  Grid,
  Typography,
  makeStyles,
  FormControl,
  FormGroup,
  FormControlLabel,
  Switch,
  colors
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import moment from 'moment';
import { enregistrerVoteElecteur } from '../Vote.actions';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    marginBottom: 10
  },
  statsItem: {
    alignItems: 'center',
    display: 'flex'
  },
  statsIcon: {
    marginRight: theme.spacing(1)
  }
}));

const ElecteurCard = ({ className, electeur }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const [vote, setVote] = useState(electeur.aVote);

  const handleChange = event => {
    dispatch(enregistrerVoteElecteur(electeur.numero, event.target.checked));
    setVote(event.target.checked);
  };

  return (
    <Card className={clsx(classes.root, className)}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography
            align="center"
            style={{ color: colors.orange[800], fontWeight: 'bold' }}
            gutterBottom
            variant="h4"
          >
            {electeur.numero}
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              Nom: {electeur.nom}
            </Typography>
            {electeur.nomJeuneFille && (
              <Typography
                align="left"
                color="textSecondary"
                gutterBottom
                variant="h4"
              >
                Nom de jeune fille: {electeur.nomJeuneFille}
              </Typography>
            )}
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="h4"
            >
              Prénoms: {electeur.prenom}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Sexe: {electeur.sexe}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              {`né le ${moment(electeur.dateNaissance).format(
                'DD/MM/YYYY'
              )} à ${electeur.lieuNaissance}`}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6}>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Nom Père: {electeur.nomPere}
            </Typography>

            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Prénom Père: {electeur.prenomPere}
            </Typography>
            <Divider />
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Nom Mère: {electeur.nomMere}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Prénom Mère: {electeur.prenomMere}
            </Typography>
            <Divider />
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              variant="body1"
            >
              Profession: {electeur.profession}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <Divider />
      <Box
        p={2}
        style={{
          backgroundColor: electeur.dateVote ? '#73DD27' : colors.common.white
        }}
      >
        <Grid container justify="space-between" spacing={2}>
          <Grid className={classes.statsItem} item>
            <AccessTimeIcon className={classes.statsIcon} color="action" />
            <Typography color="textSecondary" display="inline" variant="body2">
              {electeur.dateVote
                ? `A voté le ${moment(electeur.dateVote).format(
                    'DD/MM/YYYY à HH:mm'
                  )}`
                : "N'a pas encore voté"}
            </Typography>
          </Grid>
          <Grid className={classes.statsItem} item>
            <FormControl component="fieldset">
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={vote}
                      onChange={handleChange}
                      color="secondary"
                      name="vote"
                      disabled={electeur.dateVote}
                    />
                  }
                  label="Est venu voter"
                />
              </FormGroup>
            </FormControl>
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
};

ElecteurCard.propTypes = {
  className: PropTypes.string,
  electeur: PropTypes.object.isRequired
};

export default ElecteurCard;
