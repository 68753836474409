import {
  LISTER_RESULTAT,
  LISTER_RESULTAT_ADMIN,
  LISTER_RESULTAT_SUPERVISEUR,
  SAISIR_RESULTAT_ADMIN
} from './Resultat.constants';
import { fetchApiAction } from 'src/setup/Api/api';

export function listerResultats() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('resultat:lire', {}, result => ({
        type: LISTER_RESULTAT,
        data: result
      }))
    );
  };
}

export function saisirResultats(payload) {
  return function(dispatch) {
    dispatch(
      fetchApiAction(
        'resultat:saisir',
        { body: payload },
        result => ({
          type: SAISIR_RESULTAT_ADMIN,
          data: result
        }),
        null,
        'Le résultat a été correctement enregistrée.'
      )
    );
  };
}

export function listerResultatsAdmin() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('resultat:adminlire', {}, result => ({
        type: LISTER_RESULTAT_ADMIN,
        data: result
      }))
    );
  };
}

export function listerResultatsSuperviseur() {
  return function(dispatch) {
    dispatch(
      fetchApiAction('resultat:superviseurlire', {}, result => ({
        type: LISTER_RESULTAT_SUPERVISEUR,
        data: result
      }))
    );
  };
}
