import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useSelector } from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
  Box,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  makeStyles,
  Typography,
  Tooltip,
  IconButton
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import PhonelinkRingIcon from '@material-ui/icons/PhonelinkRing';
import WarningIcon from '@material-ui/icons/Warning';
import ModifierElecteurPopup from './ModifierElecteurPopup';
import ConnecterElecteurPopup from './ConnecterElecteurPopup';
import {
  isUserIsAdmin,
  isUserIsDepartemental
} from 'src/views/auth/Auth.selectors';
import SendSmsPopup from './SendSmsPopup';

const useStyles = makeStyles(theme => ({
  root: {},
  avatar: {
    marginRight: theme.spacing(2)
  }
}));

const Results = ({
  className,
  electeurs,
  lancerRecherche,
  pageNumero,
  setPageNumero,
  openSms,
  setOpenSms,
  ...rest
}) => {
  const classes = useStyles();

  const admin = useSelector(state => isUserIsAdmin(state));
  const departemental = useSelector(state => isUserIsDepartemental(state));

  const [open, setOpen] = useState(false);
  const [openConnxion, setOpenConnxion] = useState(false);
  const [selectedElecteur, setSelectedElecteur] = useState({});

  const handlePageChange = (event, newPage) => {
    setPageNumero(newPage);
    lancerRecherche(newPage);
  };

  return (
    <>
      {electeurs && electeurs.content.length > 0 && (
        <Card className={clsx(classes.root, className)} {...rest}>
          <PerfectScrollbar>
            <Box>
              <Typography style={{ margin: 20, fontStyle: 'italic' }}>
                Nombre d'électeurs trouvés: {electeurs.totalElements}
              </Typography>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Lieu de vote</TableCell>
                    <TableCell>Bureau de vote</TableCell>
                    <TableCell>Numéro</TableCell>
                    <TableCell>Nom Prénoms</TableCell>
                    <TableCell>Date et lieu naissance</TableCell>
                    <TableCell>Sexe</TableCell>
                    <TableCell>Téléphone</TableCell>
                    <TableCell>Profession</TableCell>
                    <TableCell>Père</TableCell>
                    <TableCell>mère</TableCell>
                    <TableCell>Statut</TableCell>
                    <TableCell>Association</TableCell>
                    <TableCell>Origine</TableCell>
                    {(admin || departemental) && <TableCell>Action</TableCell>}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {electeurs.content.map((electeur, index) => (
                    <TableRow hover key={`${electeur.id}${index}`}>
                      <TableCell>
                        {electeur.lieuVoteDTO ? electeur.lieuVoteDTO.nom : ''}

                        {electeur.contentieux2023 && electeur.motifContentieux && (
                          <Tooltip
                            title={electeur.motifContentieux}
                            classes={{ tooltip: { maxWidth: 500 } }}
                          >
                            <WarningIcon color="error" />
                          </Tooltip>
                        )}
                      </TableCell>
                      <TableCell>
                        {electeur.bureauVoteDTO
                          ? electeur.bureauVoteDTO.nom
                          : ''}
                      </TableCell>
                      <TableCell>{electeur.numero}</TableCell>
                      <TableCell>
                        {electeur.nomJeuneFille
                          ? `${electeur.nomJeuneFille} ${electeur.prenom} ép. ${electeur.nom}`
                          : `${electeur.nom} ${electeur.prenom}`}
                      </TableCell>
                      <TableCell>{`né le ${moment(
                        electeur.dateNaissance
                      ).format('DD/MM/YYYY')} à ${
                        electeur.lieuNaissance
                      }`}</TableCell>
                      <TableCell>{electeur.sexe}</TableCell>
                      <TableCell>{electeur.contact}</TableCell>
                      <TableCell>{electeur.profession}</TableCell>
                      <TableCell>{`${electeur.nomPere} ${electeur.prenomPere}`}</TableCell>
                      <TableCell>{`${electeur.nomMere} ${electeur.prenomMere}`}</TableCell>
                      <TableCell>{electeur.statut}</TableCell>
                      <TableCell>{electeur.association}</TableCell>
                      <TableCell>{electeur.origine}</TableCell>
                      {(admin || departemental) && (
                        <TableCell>
                          {electeur.numero && (
                            <IconButton
                              onClick={() => {
                                setSelectedElecteur(electeur);
                                setOpen(true);
                              }}
                            >
                              <EditIcon />
                            </IconButton>
                          )}
                          <IconButton
                            onClick={() => {
                              setSelectedElecteur(electeur);
                              setOpenConnxion(true);
                            }}
                          >
                            <PhonelinkRingIcon />
                          </IconButton>
                        </TableCell>
                      )}
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </PerfectScrollbar>
          <TablePagination
            component="div"
            count={electeurs ? electeurs.totalElements : 0}
            onChangePage={handlePageChange}
            page={pageNumero}
            rowsPerPage={20}
            rowsPerPageOptions={[20]}
            labelRowsPerPage="Ligne par page"
          />
        </Card>
      )}
      {(!electeurs || electeurs.content.length === 0) && (
        <Typography component="p">
          Aucun électeur trouvé. Veuillez affiner votre recherche.
        </Typography>
      )}

      <ModifierElecteurPopup
        open={open}
        electeur={selectedElecteur}
        handleClose={() => setOpen(false)}
      />

      <ConnecterElecteurPopup
        open={openConnxion}
        electeur={selectedElecteur}
        handleClose={() => setOpenConnxion(false)}
      />

      <SendSmsPopup open={openSms} handleClose={() => setOpenSms(false)} />
    </>
  );
};

Results.propTypes = {
  className: PropTypes.string,
  electeurs: PropTypes.object,
  lancerRecherche: PropTypes.func.isRequired,
  pageNumero: PropTypes.number,
  setPageNumero: PropTypes.func.isRequired,
  openSms: PropTypes.any,
  setOpenSms: PropTypes.func
};

export default Results;
