import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Card,
  CardContent,
  colors,
  Divider,
  Grid,
  makeStyles,
  Accordion,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ResultatTotal from './ResultatTotal';
import ResultatInfos from './ResultatInfos';

const useStyles = makeStyles({
  root: {},
  item: {
    display: 'flex',
    flexDirection: 'column'
  },
  itemDiv: {
    textAlign: 'center',
    border: '1px solid lightgray',
    borderRadius: 5
  },
  label: {
    fontSize: 10
  },
  logo: {
    margin: 'auto'
  }
});

const ResultatLv = ({ resultatGlobal, className, ...rest }) => {
  const classes = useStyles();

  if (!resultatGlobal) {
    return null;
  }

  return (
    <Card style={{ marginBottom: 10 }}>
      <CardContent>
        <Box display="flex" justifyContent="center" mb={3}>
          <Typography
            align="center"
            style={{ color: colors.orange[800], fontWeight: 'bold' }}
            gutterBottom
            variant="h6"
          >
            {`${resultatGlobal.lieuVoteDTO.numero} ${resultatGlobal.lieuVoteDTO.nom}`}
          </Typography>
        </Box>
        <Divider />
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              className={classes.label}
            >
              Département: {resultatGlobal.lieuVoteDTO.departement}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              className={classes.label}
            >
              Sous-préfecture: {resultatGlobal.lieuVoteDTO.sousPrefecture}
            </Typography>
            <Typography
              align="left"
              color="textSecondary"
              gutterBottom
              className={classes.label}
            >
              Commune: {resultatGlobal.lieuVoteDTO.commune}
            </Typography>
          </Grid>
        </Grid>

        <ResultatInfos resultat={resultatGlobal.resultatLv} />
        <Grid container spacing={3} style={{ marginBottom: 10 }}>
          {resultatGlobal.resultatLv &&
            resultatGlobal.resultatLv.resultatsCandidat.map((resG, i) => (
              <Grid key={'resG' + i} item xs={6} sm={4}>
                <ResultatTotal resultat={resG} />
              </Grid>
            ))}
        </Grid>
        <Divider />
        <Grid container style={{ marginTop: 10 }}>
          <Grid className={classes.item} item xs={12}>
            {(!resultatGlobal.resultatBvs ||
              Object.keys(resultatGlobal.resultatBvs).length === 0) && (
              <Typography
                gutterBottom
                variant="h5"
                style={{ color: colors.grey[300], textAlign: 'center' }}
              >
                Aucun résultat remonté pour ce lieu de vote
              </Typography>
            )}
            {resultatGlobal.resultatBvs &&
              resultatGlobal.resultatBvs.map((bv, i) => (
                <Accordion key={'bv' + i}>
                  <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id={'panel1a-header' + i}
                    style={{
                      backgroundColor:
                        bv.resultatBv && bv.resultatBv.votant > 0
                          ? colors.common.white
                          : colors.red[300]
                    }}
                  >
                    <Typography className={classes.heading}>
                      {bv.bureauVoteDTO.nom}
                    </Typography>
                  </AccordionSummary>
                  <AccordionDetails
                    style={{
                      display: 'block',
                      backgroundColor: colors.grey[100]
                    }}
                  >
                    <ResultatInfos resultat={bv.resultatBv} />
                    <Grid container spacing={3}>
                      {bv.resultatBv &&
                        bv.resultatBv.resultatsCandidat.map((resG, i) => (
                          <Grid key={'resG' + i} item xs={6} sm={4}>
                            <ResultatTotal resultat={resG} />
                          </Grid>
                        ))}
                    </Grid>
                  </AccordionDetails>
                </Accordion>
              ))}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

ResultatLv.propTypes = {
  className: PropTypes.string,
  resultatGlobal: PropTypes.object
};

export default ResultatLv;
