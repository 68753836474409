import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Box,
  Container,
  Grid,
  makeStyles,
  Typography,
  LinearProgress,
  Button,
  Card,
  CardContent
} from '@material-ui/core';
import Page from 'src/components/Page';
import Toolbar from './Toolbar';
import ElecteurCard from './ElecteurCard';
import { getElecteursData } from 'src/views/electeur/Electeur.selectors';
import { rechercherElecteur } from 'src/views/electeur/Electeur.actions';
import { getIsFetching } from 'src/setup/Api/Api.selectors';
import { getStatsVotes } from '../Vote.selectors';
import { lireStatsVotes } from '../Vote.actions';
import {
  isUserIsAdmin,
  isUserIsDepartemental
} from 'src/views/auth/Auth.selectors';

const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    minHeight: '100%',
    paddingBottom: theme.spacing(3),
    paddingTop: theme.spacing(3)
  }
}));

const defautlFilter = {
  numero: '',
  nom: '',
  nomJeuneFille: '',
  prenom: '',
  lieuNaissance: '',
  dateNaissance: '',
  numeroTelephone: '',
  partisant: '',
  profession: '',
  trancheAge: '',
  lv: '',
  bv: '',
  majRecente: '',
  statut: '',
  login: '',
  dp: '',
  contentieux2023: '',
  nouveauLEP2023: '',
  aVote: '',
  association: ''
};

const VoteElecteurView = () => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const electeurs = useSelector(state => getElecteursData(state));
  const statVote = useSelector(state => getStatsVotes(state));
  const fetching = useSelector(state =>
    getIsFetching(state, 'electeur:rechercher')
  );
  const userIsAdmin = useSelector(state => isUserIsAdmin(state));
  const userIsDepartemental = useSelector(state =>
    isUserIsDepartemental(state)
  );

  const [pageNumero, setPageNumero] = useState(0);
  const [filtre, setFiltre] = useState(defautlFilter);

  useEffect(() => {
    if (!statVote) {
      dispatch(lireStatsVotes());
    }
  }, [statVote, dispatch]);

  const lancerRecherche = filtreRecherche => {
    dispatch(rechercherElecteur(getComputedFilter(filtreRecherche)));
  };

  const getComputedFilter = filtreRecherche => {
    let filter = {
      pageNumero: filtreRecherche.pageNumero || 0,
      criteres: [
        { reference: 'critere', valeur: filtreRecherche.critere || '' },
        { reference: 'electeur.numero', valeur: filtreRecherche.numero || '' },
        {
          reference: 'electeur.numeroTelephone',
          valeur:
            filtreRecherche.numeroTelephone !== ''
              ? filtreRecherche.numeroTelephone
              : null
        },
        { reference: 'electeur.lv', valeur: filtreRecherche.lv || '' },
        { reference: 'electeur.nom', valeur: filtreRecherche.nom || '' },
        { reference: 'electeur.prenom', valeur: filtreRecherche.prenom || '' },
        {
          reference: 'electeur.nomJeuneFille',
          valeur: filtreRecherche.nomJeuneFille || ''
        },
        {
          reference: 'electeur.lieuNaissance',
          valeur: filtreRecherche.lieuNaissance || ''
        },
        { reference: 'electeur.lv', valeur: filtreRecherche.lv || '' },
        { reference: 'electeur.bv', valeur: filtreRecherche.bv || '' },
        {
          reference: 'electeur.dateNaissance',
          valeur:
            filtreRecherche.dateNaissance !== ''
              ? filtreRecherche.dateNaissance
              : null
        },
        {
          reference: 'electeur.profession',
          valeur: filtreRecherche.profession || ''
        },
        {
          reference: 'electeur.trancheAge',
          valeur: filtreRecherche.trancheAge || ''
        },
        {
          reference: 'electeur.dp',
          valeur: filtreRecherche.dp || ''
        },
        {
          reference: 'electeur.statut',
          valeur: filtreRecherche.statut || ''
        },
        {
          reference: 'electeur.login',
          valeur: filtreRecherche.login || ''
        },
        {
          reference: 'electeur.majRecente',
          valeur:
            filtreRecherche.majRecente !== ''
              ? filtreRecherche.majRecente
              : null
        },
        {
          reference: 'electeur.contentieux2023',
          valeur:
            filtreRecherche.contentieux2023 !== ''
              ? filtreRecherche.contentieux2023
              : null
        },
        {
          reference: 'electeur.aVote',
          valeur: filtreRecherche.aVote !== '' ? filtreRecherche.aVote : null
        },
        {
          reference: 'electeur.nouveauLEP2023',
          valeur:
            filtreRecherche.nouveauLEP2023 !== ''
              ? filtreRecherche.nouveauLEP2023
              : null
        },
        {
          reference: 'electeur.association',
          valeur: filtreRecherche.association || ''
        }
      ]
    };

    return filter;
  };

  const lancerRecherchePagination = page => {
    lancerRecherche({ ...filtre, pageNumero: page });
  };

  return (
    <Page className={classes.root} title="Votes">
      <Container maxWidth={false}>
        <Toolbar
          lancerRecherche={lancerRecherche}
          filtre={filtre}
          setFiltre={setFiltre}
        />
        <Box mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {fetching && (
                <LinearProgress
                  classes={{
                    colorPrimary: 'primary'
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Card>
                <CardContent>
                  {statVote && (
                    <>
                      <Typography
                        gutterBottom
                        variant="h6"
                        style={{ color: 'grey' }}
                      >
                        {!userIsAdmin && !userIsDepartemental && (
                          <span>{`Nombre de votes enregistrés sur le BV '${statVote.bv}'`}</span>
                        )}
                        {userIsDepartemental && (
                          <span>{`Nombre de votes enregistrés sur le LV '${statVote.lv}'`}</span>
                        )}
                        {userIsAdmin && (
                          <span>{`Nombre de votes enregistrés`}</span>
                        )}
                      </Typography>
                      <Typography variant="h2" color="primary">
                        {statVote.voteEnregistres}
                      </Typography>
                    </>
                  )}
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12}>
              {electeurs &&
                electeurs.content &&
                electeurs.content.length > 0 &&
                electeurs.content.map(electeur => (
                  <ElecteurCard
                    key={electeur.numero}
                    className={classes.electeurCard}
                    electeur={electeur}
                  />
                ))}

              {(!electeurs ||
                !electeurs.content ||
                electeurs.content.length === 0) && (
                <Typography>
                  Aucun électeur trouvé. Veuillez affiner votre recherche.
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} style={{ textAlign: 'center' }}>
              {electeurs &&
                electeurs.content &&
                electeurs.totalPages > pageNumero + 1 && (
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => {
                      lancerRecherchePagination(pageNumero + 1);
                      setPageNumero(pageNumero + 1);
                      window.scrollTo({ top: 0, behavior: 'smooth' });
                    }}
                  >
                    Page suivante... (
                    {`${pageNumero + 1} / ${electeurs.totalPages}`})
                  </Button>
                )}
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Page>
  );
};

export default VoteElecteurView;
